import styles from "./PasswordSetByUser.module.scss";

const PasswordSetByUser = () => {
  return (
    <div className={styles.invalid_link}>
      <div>招待が完了しました。</div>
      <div>メールアドレス、パスワードを入力してログインし直してください。</div>
      <a href="/">ログイン画面にもどる。</a>
    </div>
  );
};

export default PasswordSetByUser;
