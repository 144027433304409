import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { moreThanMessage, requiredMessage } from "../errorMessage";
import { isValidPhoneNumber } from "utils/phoneNumber";

export type CancelForm = yup.Asserts<typeof cancelScheme>;

export const cancelScheme = yup.object({
  visitCounts: yup
    .number()
    .transform((v) => (isNaN(v) ? undefined : v))
    .min(0, moreThanMessage(0)),
  originPrice: yup
    .number()
    .transform((v) => (isNaN(v) ? undefined : v))
    .required(moreThanMessage(0))
    .min(0, moreThanMessage(0)),
  name: yup.string().required(requiredMessage("請求先")),
  // staff_name: yup.string(),
  phoneNumber: yup
    .string()
    .required(requiredMessage("電話番号"))
    .test(
      "isValidJapanDomesticPhoneNumber",
      "0から始まる正しい電話番号を入力してください。",
      (value) => {
        if (!value) return true;
        return isValidPhoneNumber(value);
      }
    ),
});

export const cancelResolver = yupResolver(cancelScheme);
