import { maintenanceFrom, maintenanceTo } from "utils/env/maintenance";
import styles from "./Maintenance.module.scss";

const covertDateToString = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const Maintenance = () => {
  const fromString = maintenanceFrom ? covertDateToString(maintenanceFrom) : "";
  const toString = maintenanceTo ? covertDateToString(maintenanceTo) : "";
  return (
    <div className={styles.maintenance_page}>
      <h1>ただいまメンテナンス中です</h1>
      <p>ご不便おかけしております。</p>
      <p>現在、メンテナンス中です。</p>
      <p>ご理解いただきまして、ありがとうございます。</p>
      <br />
      <h2>メンテナンス日時</h2>
      <p>
        {fromString} ~{toString}
      </p>
    </div>
  );
};

export default Maintenance;
