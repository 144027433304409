import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { EmailFormLabel } from "components/features/payment/EmailFormLabel";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiService from "components/provider/rest";
import styles from "./Payment.module.scss";
import StripeForm, { PaymentMethodType } from "./StripeForm";
import { isEmailRequired, isValidEmailForPayment } from "./logic";

// TODO: 支払が取り消しされたときに支払い画面を開いた場合を実装する
// TODO: 支払取消と支払いが同時に起こった時を検討する
// TODO: stripe支払いは終わったが、invoice_paidが失敗している場合にどう見せるか検討する
function Payment() {
  const navigate = useNavigate();
  const stripePromise = process.env.REACT_APP_STRIPE_KEY
    ? loadStripe(process.env.REACT_APP_STRIPE_KEY)
    : null;

  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [amountRemaining, setAmountRemaining] = useState<number | undefined>(
    undefined
  );
  const options = {
    clientSecret: stripeClientSecret,
    appearance: {
      theme: "stripe" as const, // "stripe" | "night" | "flat" のいずれかにする
    },
  };
  const paymentID = useParams().payment_id;
  const location = useLocation();

  /* states */
  const [showCouponDetail, setShowCouponDetail] = useState(false);
  const [email, setEmail] = useState("");
  const [cancelData, setCancelData] = useState({
    id: "",
    name: "",
    amount: 0,
    expDate: "",
    payment: {
      salonName: "",
      salonAddress: "",
      paymentDescriptions: [""],
    },
    is_aborted: false,
    couponMaster: {
      id: "",
      name: "",
      targetName: "",
      discountAmount: 0,
      discountPercentage: 0,
      couponDiscountAmount: 0,
      expire_duration_day: 0,
      min_amount: 0,
      precautions: [],
      showDiscountAmount: false,
      isRigakuBody: false,
    } as any,
  });
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>("card");
  const couponMasterExists =
    cancelData.couponMaster && cancelData.couponMaster.name;
  const couponDiscountAmountText = useMemo(() => {
    if (couponMasterExists) {
      if (cancelData.couponMaster.discountAmount) {
        return "￥" + cancelData.couponMaster.couponDiscountAmount;
      } else if (cancelData.couponMaster.discountPercentage) {
        return (
          "￥" +
          cancelData.couponMaster.couponDiscountAmount +
          " (お支払い金額の" +
          cancelData.couponMaster.discountPercentage +
          "%から二桁円以下を切り捨てた金額)"
        );
      }
    }
    return "";
  }, [cancelData.couponMaster, couponMasterExists]);

  const getCancelData = async () => {
    const res = await apiService.get("/public/payment/" + paymentID);
    if (res.payment_status === 200) {
      navigate("/payment/complete/" + paymentID);
    }
    setCancelData({
      id: res.cancel_id,
      name: res.name,
      amount: res.invoice_price,
      expDate: res.payment_deadline,
      payment: {
        salonName: res.salon.name,
        salonAddress: res.salon.address,
        paymentDescriptions: res.payment_descriptions,
      },
      is_aborted: res.is_aborted,
      couponMaster: res.coupon_master
        ? {
            id: res.coupon_master.id,
            name: res.coupon_master.name,
            targetName: res.coupon_master.target_name,
            discountAmount: res.coupon_master.discount_amount,
            discountPercentage: res.coupon_master.discount_percent,
            couponDiscountAmount: res.coupon_master.coupon_discount_amount,
            expire_duration_day: res.coupon_master.expire_duration_day,
            min_amount: res.coupon_master.min_amount,
            precautions: res.coupon_master.precautions,
            showDiscountAmount: res.coupon_master.show_discount_amount,
            isRigakuBody: res.coupon_master.is_rigaku_body,
          }
        : null,
    });
  };
  const getStripeClientSecret = async () => {
    const res = await apiService.get("/public/payment/intent/" + paymentID);
    setStripeClientSecret(res.client_secret);
    setAmountRemaining(res.amount_remaining);
  };
  const create_invoice_access_history = async () => {
    // TODO: implement
    return;
  };

  useEffect(() => {
    getCancelData();
    getStripeClientSecret();
    create_invoice_access_history();
  }, [paymentID]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setEmail(queryParams.get("email") ?? "");
  }, [location.search]);

  const toggleCouponDetail = () => {
    setShowCouponDetail(!showCouponDetail);
  };

  return (
    <div>
      {cancelData.is_aborted ? (
        <div className={styles.payment_aborted}>
          請求は取り消しされました。
          <br />
          またのご来店をお待ちしております。
          <br />
          <br />
          {cancelData.payment.salonName}
          <br />
          {cancelData.payment.salonAddress}
        </div>
      ) : (
        <div className={styles.payment_page}>
          <div className={styles.payment_header}>請求書</div>
          <div className={styles.payment_body}>
            <h3 className={styles.subtitle}>ご請求内容</h3>
            {cancelData.payment.paymentDescriptions.map((desc, i) => {
              return (
                <p key={i} className={styles.payment_desc}>
                  {desc}
                </p>
              );
            })}
            <table className={styles.payment_detail_table}>
              <tbody>
                <tr>
                  <td className={styles.td_left}>お名前</td>
                  <td className={styles.td_right}>{cancelData.name} 様</td>
                </tr>
                <tr>
                  <td className={styles.td_left}>ご請求金額</td>
                  <td className={styles.td_right}>￥{amountRemaining ?? ""}</td>
                </tr>
                <tr>
                  <td className={styles.td_left}>お支払い期日</td>
                  <td className={styles.td_right}>{cancelData.expDate}</td>
                </tr>
                <tr>
                  <td className={styles.td_left}>お支払い先</td>
                  <td className={styles.td_right}>
                    {cancelData.payment.salonName}
                    <br />
                    {cancelData.payment.salonAddress}
                  </td>
                </tr>
                {couponMasterExists && (
                  <tr>
                    <td className={styles.td_left}>お支払い特典</td>
                    <td className={styles.td_right}>
                      期日までに支払うと
                      <br />
                      <b>{cancelData.couponMaster.name}</b>
                      をプレゼント
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {couponMasterExists && (
              <>
                <div
                  className={styles.coupon_detail}
                  style={{
                    maxHeight: showCouponDetail ? "1000px" : "0px",
                    overflow: "hidden",
                    transition: "max-height 0.5s ease-out",
                  }}
                >
                  <h4 className={styles.section_title}>
                    {cancelData.couponMaster.isRigakuBody
                      ? "施術券内容"
                      : "クーポン内容"}
                  </h4>
                  <table
                    className={`${styles.coupon} ${styles.payment_detail_table}`}
                  >
                    <tbody>
                      <tr>
                        <td className={styles.td_left}>割引対象</td>
                        <td className={styles.td_right}>
                          {cancelData.couponMaster.targetName}
                        </td>
                      </tr>
                      {cancelData.couponMaster.showDiscountAmount && (
                        <tr>
                          <td className={styles.td_left}>割引金額</td>
                          <td className={styles.td_right}>
                            {couponDiscountAmountText}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className={styles.td_left}>ご利用方法</td>
                        <td className={styles.td_right}>
                          {cancelData.couponMaster.isRigakuBody
                            ? "次回来店時に施術券提示"
                            : "次回来店時にクーポン提示"}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.td_left}>有効期限</td>
                        <td className={styles.td_right}>
                          お支払い後
                          {cancelData.couponMaster.expire_duration_day}
                          日以内
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.td_left}>利用回数</td>
                        <td className={styles.td_right}>期限内で1回限り有効</td>
                      </tr>
                      <tr>
                        <td className={styles.td_left}>最低利用金額</td>
                        <td className={styles.td_right}>
                          ￥{cancelData.couponMaster.min_amount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className={styles.section_title}>注意事項</h4>
                  <ul className={styles.payment_ul}>
                    {cancelData?.couponMaster?.precautions?.map(
                      (precaution, _) => (
                        <li>{precaution}</li>
                      )
                    )}
                  </ul>
                </div>
                <p
                  className={styles.coupon_detail_switch}
                  onClick={toggleCouponDetail}
                >
                  {showCouponDetail ? "詳細を閉じる" : "詳細を見る"}
                </p>
              </>
            )}

            <div className={styles.email_form}>
              <EmailFormLabel
                required={isEmailRequired(paymentMethod)}
                couponMasterExists={couponMasterExists}
              />
              <input
                type="email"
                placeholder="example@gmail.com"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className={styles.warning}>
              {!isValidEmailForPayment(paymentMethod, email) && (
                <span>メールアドレスを正しく入力してください</span>
              )}
            </div>
            <h3 className={`${styles.payment_method} ${styles.subtitle}`}>
              お支払い方法
            </h3>
            <div>
              {paymentID && stripeClientSecret && amountRemaining && (
                <Elements options={options} stripe={stripePromise}>
                  <StripeForm
                    amount={amountRemaining}
                    clientSecret={stripeClientSecret}
                    paymentID={paymentID}
                    email={email}
                    paymentMethod={paymentMethod}
                    onChangePaymentMethod={setPaymentMethod}
                  />
                </Elements>
              )}
              <hr />
            </div>
            <div className={styles.notes}>
              <div>※留意事項</div>
              <div>入れ違いで請求書通知が届く場合がございます</div>
              <div>振込確認完了まで銀行の営業日で1日ほどかかります</div>
              <div>振込確認が完了後、決済完了通知が届きます</div>
            </div>
          </div>
          <div className={styles.footer}>請求書コード: {paymentID}</div>
        </div>
      )}
    </div>
  );
}

export default Payment;
