import {
  SalonUserAllFieldFragment,
  UserPermission,
} from "gql/__generated__/graphql";
import styles from "./MobileSalonUsersTableView.module.scss";
import SimpleAttributeRow from "components/ui/SimpleAttributeRow/SimpleAttributeRow";
import { formatDateUntilDay } from "utils/date";
import _ from "lodash";
import {
  deleteButtonVisible,
  editRoleButtonVisible,
  getVisibility,
  reinviteButtonVisible,
  role_text,
  switchOwnerButtonVisible,
} from "./SalonUserstableView.logic";

type Props = {
  mySalonUser: SalonUserAllFieldFragment;
  salonUsers: readonly SalonUserAllFieldFragment[];
  userPermission: UserPermission;
  openUpdateSalonUserRoleModal: (salonUser: SalonUserAllFieldFragment) => void;
  onClickReinviteSalonUserButton: (
    salonUser: SalonUserAllFieldFragment
  ) => void;
  onClickSwitchOwnerButton: (userID: string) => void;
  onClickDeleteSalonUserButton: (userID: string) => void;
};

type EmailProps = {
  email: string;
};

const Email = ({ email }: EmailProps) => {
  return (
    <div>
      {/* FIXME: もっと長かったら3行とかの方がいい */}
      {email.length > 15 ? (
        <div className={styles.email}>
          <span className={styles.inline_block}>{email.split("@")[0]}</span>
          <span className={styles.inline_block}>
            {"@" + email.split("@")[1]}
          </span>
        </div>
      ) : (
        <div>{email}</div>
      )}
    </div>
  );
};

const MobileSalonUsersTableView = ({
  mySalonUser,
  salonUsers,
  userPermission,
  openUpdateSalonUserRoleModal,
  onClickReinviteSalonUserButton,
  onClickSwitchOwnerButton,
  onClickDeleteSalonUserButton,
}: Props) => {
  return (
    <div className={styles.table_view}>
      {salonUsers.map((row, _) => (
        <div className={styles.item}>
          <div className={styles.attribute}>
            <SimpleAttributeRow
              label="メールアドレス"
              content={() => <Email email={row.email} />}
            />
          </div>
          <div className={styles.attribute}>
            <SimpleAttributeRow label="役割" content={role_text(row.role)} />
          </div>
          <div className={styles.attribute}>
            <SimpleAttributeRow
              label="招待日"
              content={formatDateUntilDay(new Date(row.createTime))}
            />
          </div>
          <div className={styles.attribute}>
            <SimpleAttributeRow
              label="最終ログイン日"
              content={
                row.lastLoginTime
                  ? formatDateUntilDay(new Date(row.lastLoginTime))
                  : "招待メール送信済み"
              }
            />
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.action_button}
              style={{
                visibility: getVisibility(
                  editRoleButtonVisible(
                    row,
                    mySalonUser ?? null,
                    userPermission
                  )
                ),
              }}
              onClick={() => openUpdateSalonUserRoleModal(row)}
            >
              編集
            </button>
            <span>
              {reinviteButtonVisible(row, userPermission) ? (
                <button
                  className={styles.action_button}
                  style={{
                    visibility: getVisibility(
                      reinviteButtonVisible(row, userPermission)
                    ),
                  }}
                  onClick={() => onClickReinviteSalonUserButton(row)}
                >
                  招待メール再送
                </button>
              ) : (
                <button
                  className={styles.action_button}
                  style={{
                    visibility: getVisibility(
                      switchOwnerButtonVisible(
                        row,
                        mySalonUser ?? null,
                        userPermission
                      )
                    ),
                  }}
                  onClick={() => onClickSwitchOwnerButton(row.id)}
                >
                  オーナーの変更
                </button>
              )}
            </span>
            <button
              className={styles.action_button}
              style={{
                visibility: getVisibility(
                  deleteButtonVisible(row, mySalonUser ?? null, userPermission)
                ),
              }}
              onClick={() => onClickDeleteSalonUserButton(row.id)}
            >
              削除
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileSalonUsersTableView;
