import { ReactNode, useEffect, useState } from "react";
import hamburger from "images/hamburger.svg";
import styles from "./Layout.module.scss";
import Sidebar from "components/features/sidebar/Sidebar";
import logo from "images/logo.svg";
import * as Dialog from "@radix-ui/react-dialog";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import { fetchAuthSession } from "aws-amplify/auth";
import classNames from "classnames";

type Props = { children: ReactNode };

const Layout = ({ children }: Props) => {
  const { isMobile } = useMediaQueryContext();
  const [openSidebar, setOpenSidebar] = useState(!isMobile);
  const [email, setEmail] = useState("");
  const hideSidebar = window.location.pathname.startsWith("/invitation");

  useEffect(() => {
    fetchAuthSession()
      .then((session) => {
        // @ts-ignore
        setEmail(session.tokens?.idToken.payload.email ?? "");
      })
      .catch((error) => {
        console.log("fetching email failed", error);
      });
  }, []);

  let mainClassNames = styles.main;
  if (!isMobile && openSidebar) {
    mainClassNames = classNames(styles.main, styles.padding_left_for_sidebar);
  }

  return (
    <div className={styles.app}>
      {/* sidebar */}
      {!hideSidebar && !isMobile && openSidebar && (
        <div className={styles.sidebar}>
          <Sidebar
            email={email}
            onClickHamburger={() => {
              setOpenSidebar(false);
            }}
          />
        </div>
      )}
      {!hideSidebar && isMobile && (
        <Dialog.Root open={openSidebar} onOpenChange={setOpenSidebar}>
          <Dialog.Portal>
            <Dialog.Overlay className={styles.DialogOverlay} />
            <Dialog.Content>
              <div className={styles.sidebar}>
                <Sidebar
                  email={email}
                  onClickHamburger={() => setOpenSidebar(false)}
                />
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      )}

      <div className={mainClassNames}>
        <div
          style={{ position: isMobile ? undefined : "fixed" }}
          className={styles.header}
        >
          {!openSidebar && (
            <img
              src={hamburger}
              alt="hamburger"
              width={30}
              height={30}
              className={styles.hamburger}
              onClick={() => setOpenSidebar(!openSidebar)}
            />
          )}
          {isMobile && <img src={logo} alt="Logo" className={styles.logo} />}
          {/* enableする予定
          {isMobile && (
            <img
              src={location_reload}
              alt="location_reload"
              className={styles.location_reload}
              onClick={() => {
                window.location.href = window.location.href;
              }}
            />
          )} */}
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
