import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import QuestionHoverCard from "./QuestionHoverCard/QuestionHoverCard";
import QuestionPopover from "./QuestionPopover/QuestionPopover";

type Props = {
  contentMessage?: string;
  children?: React.ReactNode;
};

const QuestionExplanationIcon = ({ contentMessage, children }: Props) => {
  const { isMobile } = useMediaQueryContext();

  if (children) {
    return (
      <span>
        {isMobile ? (
          <QuestionPopover contentMessage={contentMessage}>
            {children}
          </QuestionPopover>
        ) : (
          <QuestionHoverCard contentMessage={contentMessage}>
            {children}
          </QuestionHoverCard>
        )}
      </span>
    );
  } else {
    return (
      <span>
        {isMobile ? (
          <QuestionPopover contentMessage={contentMessage} />
        ) : (
          <QuestionHoverCard contentMessage={contentMessage} />
        )}
      </span>
    );
  }
};

export default QuestionExplanationIcon;
