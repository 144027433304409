import styles from "./MobileTableRowAttribute.module.scss";
import QuestionExplanationIcon from "components/ui/QuetionExplanationIcon/QuestionExplanationIcon";

type Props = {
  label: string;
  content: string;
  questionPopoverMessage?: string;
};

// should be combined with ui/SimpleAttribute
export default function Attribute({
  label,
  content,
  questionPopoverMessage,
}: Props) {
  return (
    <div className={styles.attribute}>
      <div className={styles.label}>
        {label}
        {questionPopoverMessage && (
          <QuestionExplanationIcon contentMessage={questionPopoverMessage} />
        )}
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );
}
