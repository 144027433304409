import React, {
  createContext,
  useContext,
  useState,
  type ReactNode,
  useCallback,
  useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";
import OverlayLoading from "../layout/OverlayLoading/OverlayLoading";

type LoadingContextType = {
  loading: boolean;
  /** @returns function to finish loading */
  startLoading: () => () => void;
};
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export function LoadingProvider({ children }: { children: ReactNode }) {
  const [requestLoadingStatus, setRequestLoadingStatus] = useState<
    ReadonlySet<string>
  >(() => new Set());

  const startLoading = useCallback(() => {
    const operationId = uuidv4();
    setRequestLoadingStatus((prevState) => new Set(prevState).add(operationId));
    return () => {
      setRequestLoadingStatus((prevState) => {
        const newSet = new Set(prevState);
        newSet.delete(operationId);
        return newSet;
      });
    };
  }, []);

  const loading = useMemo(
    () => requestLoadingStatus.size > 0,
    [requestLoadingStatus]
  );
  const ctx = useMemo(
    () => ({ loading, startLoading }),
    [loading, startLoading]
  );

  return (
    <LoadingContext.Provider value={ctx}>
      {loading && <OverlayLoading />}
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useContext must be used within a LoadingProvider");
  }
  return context;
};
