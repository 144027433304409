export const getURLParams = (url: string): Record<string, string> => {
  const searchParams = new URLSearchParams(url.split("?")[1]);
  const keys = searchParams.keys();

  const result: Record<string, string> = {};
  for (const key of keys) {
    const value = searchParams.get(key);
    if (value) {
      result[key] = value;
    }
  }
  return result;
};

export const urlSafeEncode = (str: string): string => {
  const utf8 = new TextEncoder().encode(str);
  const base64 = btoa(String.fromCharCode.apply(null, utf8 as unknown as number[]));
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const urlSafeDecode = (str: string): string => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  const paddedBase64 = base64.padEnd(base64.length + (4 - base64.length % 4) % 4, '=');
  const bytes = Uint8Array.from(atob(paddedBase64), c => c.charCodeAt(0));
  return new TextDecoder().decode(bytes);
};
