import {
  CancelPaymentStatus,
  CancelForListFragment,
  CouponMasterForListFragment,
  CouponMasterNameFragment,
  CancelType,
  CancelForRereservationFragment,
} from "gql/__generated__/graphql";
import { EditingRereservationRow, EditingRow, Tab } from "../HomePage.logic";
import styles from "./MobileCancelsForRereservationTableView.module.scss";
import {
  convertCancelTypeToString,
  convertStringToCancelType,
} from "domain/cancel";
import { showEditButton } from "domain/home";
import {
  STATUS,
  getCouponMasterSelectDefaultValue,
  getInvoicePrice,
  getReserveId,
  isEditablePaymentStatus,
  renderVisitCount,
  showCheckbox,
  showUpdatePaymentStatusButton,
  updatePaymentStatusButtonTitle,
} from "./CancelsTableView.common.logic";
import ActionButton from "./ActionButton/ActionButton";
import _ from "lodash";
import { useRef } from "react";
import useOnScreen from "utils/hooks/useOnScreen";
import { TriangleUpIcon } from "@radix-ui/react-icons";
import CouponMasterSelect from "./CouponMasterSelect/CouponMasterSelect";
import {
  canEdit,
  getSpanClass,
  rereservationStatusText,
} from "./CancelsForRereservationTableView.logic";

type Props = {
  cancels: readonly CancelForRereservationFragment[];
  editingRereservationRowMap: Record<string, EditingRereservationRow>; // key is idv2
  updateEditingRereservationRow: (
    idv2: string,
    row: EditingRereservationRow
  ) => void;
  onClickUpdateButton: (idv2: string) => void;
  onClickActionButton: (idv2: string) => void;
  onClickRereservationRowEdit: (idv2: string) => void;
  cancelRereservationRowEdit: (idv2: string) => void;
};

export const MobileCancelsForRereservationView = ({
  cancels,
  editingRereservationRowMap,
  updateEditingRereservationRow,
  onClickUpdateButton,
  onClickActionButton,
  onClickRereservationRowEdit,
  cancelRereservationRowEdit,
}: Props) => {
  const isEditingRow = (idv2: string): boolean => {
    return idv2 in editingRereservationRowMap;
  };

  // TODO: 全てCancelsTableViewと同じロジックなので複雑なやつはそれぞれcomponent化するべき
  return (
    <div className={styles.mobile_cancels_table_view}>
      <div className={styles.header}>
        <div className={styles.cancels_count}>{cancels.length}件の結果</div>
      </div>

      {cancels.map((row, _) => (
        <div className={styles.item} key={row.idv2}>
          <div className={styles.attribute}>
            <div className={styles.label}>請求ステータス</div>
            <div className={styles.content}>
              {(() => {
                return (
                  <span
                    className={`${getSpanClass(row, styles)} ${
                      styles.table_elm_background
                    }`}
                  >
                    {rereservationStatusText[row.rereservationStatus]}
                  </span>
                );
              })()}
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>キャンセル種別</div>
            <div className={styles.content}>
              <span>{convertCancelTypeToString(row.cancelType)}</span>
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>予約ID</div>
            <div className={styles.content}>{getReserveId(row)}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>来店回数</div>
            <div className={styles.content}>{renderVisitCount(row)}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>予約金額</div>
            <div className={styles.content}>
              <span>¥{row.originPrice}</span>
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>来店予定日</div>
            <div className={styles.content}>{row.dateVisit}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>キャンセル処理日</div>
            <div className={styles.content}>{row.dateCancel}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>担当スタッフ</div>
            <div className={styles.content}>{row.staffName}</div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>請求先</div>
            <div className={styles.content}>
              <span>{row.name}</span>
            </div>
          </div>

          <div className={styles.attribute}>
            <div className={styles.label}>電話番号</div>
            <div className={styles.content}>
              {isEditingRow(row.idv2) ? (
                <input
                  className={styles.editing_cell}
                  type="number"
                  value={editingRereservationRowMap[row.idv2].phoneNumber}
                  onChange={(event) => {
                    const newRow: EditingRereservationRow = {
                      ...editingRereservationRowMap[row.idv2],
                      phoneNumber: event.target.value,
                    };
                    updateEditingRereservationRow(row.idv2, newRow);
                  }}
                />
              ) : (
                <span>{row.phoneNumber}</span>
              )}
            </div>
          </div>

          <div className={styles.footer}>
            <div className={styles.checkbox_edit_container}>
              {canEdit(row.rereservationStatus) && (
                <div className={styles.edit_button}>
                  {isEditingRow(row.idv2) ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        cancelRereservationRowEdit(row.idv2);
                      }}
                    >
                      編集取消
                    </a>
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onClickRereservationRowEdit(row.idv2);
                      }}
                    >
                      編集
                    </a>
                  )}
                </div>
              )}
            </div>

            {row.rereservationStatus ===
              "ENCOURAGING_RERESERVATION_NOT_SCHEDULED" && (
              <div className={styles.action_button}>
                <ActionButton
                  rereservationRow={row}
                  isEditingRow={isEditingRow(row.idv2)}
                  onClickUpdateButton={() => onClickUpdateButton(row.idv2)}
                  onClickActionButton={() => onClickActionButton(row.idv2)}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileCancelsForRereservationView;
