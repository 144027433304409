import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import logo from "images/logo.svg";
import hamburger from "images/hamburger.svg";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import { useQuery } from "@apollo/client";
import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  SalonNameFragmentDoc,
} from "gql/__generated__/graphql";
import SalonSelect from "./SalonSelect/SalonSelect";
import useSwitchSalon from "components/provider/auth/useSwitchSalon";
import useLogout from "components/provider/auth/useLogout";
import { getFragmentData } from "gql/__generated__";
import { PlusIcon } from "@radix-ui/react-icons";

type Props = {
  email: string;
  onClickHamburger: () => void;
};

const Sidebar = ({ email, onClickHamburger }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useMediaQueryContext();
  const { data } = useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, {
    fetchPolicy: "cache-first",
  });
  const switchSalon = useSwitchSalon();
  const logout = useLogout();
  const onClickLogout = (e) => {
    e.preventDefault();
    logout().then(() => {
      window.location.reload();
    });
  };
  const isCouponMasterPath =
    location.pathname === "/coupon_masters" ||
    location.pathname === "/coupon_masters/new";

  return (
    <div className="sidebar">
      <div className="hamburger_container">
        <img
          src={hamburger}
          alt="Hamburger"
          width={30}
          height={30}
          className="hamburger"
          onClick={onClickHamburger}
        />
      </div>
      {!isMobile && (
        <div className="sidebar__header">
          <img src={logo} alt="Logo" className="sidebar__logo" />
        </div>
      )}

      {data?.me.salons && (
        <SalonSelect
          salons={getFragmentData(SalonNameFragmentDoc, data?.me.salons)}
          onChangeSalon={switchSalon}
        />
      )}

      <ul className="sidebar__index">
        <li
          className="sidebar__new_cancel_button_container"
          onClick={() => navigate("/new_cancel")}
        >
          <PlusIcon />
          キャンセル情報作成
        </li>
        <li
          className={`sidebar__index-elm ${
            location.pathname === "/" ? "sidebar__active" : ""
          }`}
          onClick={() => {
            navigate("/");
          }}
        >
          <a>ホーム</a>
        </li>
        <li
          className={`sidebar__index-elm ${
            isCouponMasterPath ? "sidebar__active" : ""
          }`}
          onClick={() => {
            navigate("/coupon_masters");
          }}
        >
          <a>特典</a>
        </li>
        <li
          className={`sidebar__index-elm ${
            location.pathname === "/claim_rule" ? "sidebar__active" : ""
          }`}
          onClick={() => {
            navigate("/claim_rule");
          }}
        >
          <a>請求ルール</a>
        </li>
        <li
          className={`sidebar__index-elm ${
            location.pathname === "/salon_settings" ? "sidebar__active" : ""
          }`}
          onClick={() => {
            navigate("/salon_settings");
          }}
        >
          <a>店舗設定</a>
        </li>
        <li>
          <a
            href="https://refundhub-help.notion.site/RefundHub-ad994c70cc064210b3b6b70a8485a01e"
            target="_blank"
            rel="noreferrer"
          >
            ヘルプページ
          </a>
        </li>
      </ul>

      <div className="sidebar__footer">
        <p>{email}</p>
        <a href="/" onClick={onClickLogout} className="sidebar__logout">
          ログアウト
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
