import React, { useState, useEffect } from "react";

type Position = undefined | "ABOVE_VIEWPORT" | "BELOW_VIEWPORT" | "VISIBLE";

// あるelementが画面外にあるかどうかを判定するカスタムhook
// https://zenn.dev/catnose99/articles/0f60eae4b11e44
const useOnScreen = (targetRef: React.RefObject<HTMLElement>) => {
  const [position, setPosition] = useState<Position>(undefined);

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        setPosition("VISIBLE"); // 画面内に表示中
        return;
      }
      if (entry.boundingClientRect.top > 0) {
        setPosition("BELOW_VIEWPORT"); // 画面より下に表示中
      } else {
        setPosition("ABOVE_VIEWPORT"); // 画面より上に表示中
      }
    },
    {
      root: null,
      threshold: 0,
    }
  );

  useEffect(() => {
    // マウント時にobserverを登録
    if (targetRef.current) observer.observe(targetRef.current);

    // アンマウント時にobserverを解除
    return () => {
      observer.disconnect();
    };
  }, []);

  return position;
};

export default useOnScreen;
