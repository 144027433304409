import * as Tabs from "@radix-ui/react-tabs";
import styles from "./SalonSettingsPage.module.scss";
import SalonDetailSetting from "./SalonDetailSetting/SalonDetailSetting";
import UsersSetting from "./UsersSetting/UsersSetting";
import BankAccountSetting from "./BankAccountSetting/BankAccountSetting";

enum Tab {
  SALON_DETAIL_SETTING = "SALON_DETAIL_SETTING",
  USERS_SETTING = "USERS_SETTING",
  BANK_ACCOUNT_SETTING = "BANK_ACCOUNT_SETTING",
}

const SalonSettingsPage = () => {
  return (
    <div className={styles.salon_settings_page}>
      <div className={styles.top_content}>
        <h1>店舗設定</h1>
      </div>

      <div className={styles.main_content}>
        <Tabs.Root defaultValue={Tab.SALON_DETAIL_SETTING}>
          <Tabs.List className={styles.tabs_container}>
            <Tabs.Trigger
              value={Tab.SALON_DETAIL_SETTING}
              className={styles.tab}
            >
              店舗情報
            </Tabs.Trigger>
            <Tabs.Trigger value={Tab.USERS_SETTING} className={styles.tab}>
              ユーザー
            </Tabs.Trigger>
            {/* <Tabs.Trigger
              value={Tab.BANK_ACCOUNT_SETTING}
              className={styles.tab}
            >
              銀行口座
            </Tabs.Trigger> */}
          </Tabs.List>

          <Tabs.Content value={Tab.SALON_DETAIL_SETTING}>
            <SalonDetailSetting />
          </Tabs.Content>
          <Tabs.Content value={Tab.USERS_SETTING}>
            <UsersSetting />
          </Tabs.Content>
          <Tabs.Content value={Tab.BANK_ACCOUNT_SETTING}>
            <BankAccountSetting />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  );
};

export default SalonSettingsPage;
