import { fetchAuthSession } from "aws-amplify/auth";
import { v4 as uuidv4 } from "uuid";
import {
  FetchMiddleware,
  withCallback,
  withHeaders,
  wrapFetch,
} from "./wrapFetch";

type CreateApolloFetch = {
  currentSalonID: number;
  currentUserID: string;
  onRequestStart: () => () => void;
};

export const createApolloFetch = ({
  currentSalonID,
  currentUserID,
  onRequestStart,
}: CreateApolloFetch): typeof fetch => {
  return wrapFetch(fetch, [
    withCallback(onRequestStart),
    withAuthHeaders(currentSalonID, currentUserID),
    withRequestId(),
    // statusコード見た時のhandlingとか行う。
  ]);
};

const withAuthHeaders = (
  currentSalonID: number,
  currentUserID: string
): FetchMiddleware => {
  return (next) => async (input, init) => {
    const session = await fetchAuthSession();
    const token = session.tokens?.accessToken.toString() ?? "";

    const additionalHeaders: Record<string, string> = {
      Authorization: token ? `Bearer ${token}` : "",
      "Rh-Salon-Id": currentSalonID.toString(),
      "Rh-User-Id": currentUserID,
    };

    return withHeaders(additionalHeaders)(next)(input, init);
  };
};

function withRequestId(): FetchMiddleware {
  return (next) => (input, init) => {
    const additionalHeaders: Record<string, string> = {
      "Rh-Request-Id": uuidv4(),
    };

    return withHeaders(additionalHeaders)(next)(input, init);
  };
}
