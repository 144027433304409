import type { GraphQLError } from "graphql";

const code_key = "code";

export const getExtensionsCode = (
  graphqlError: GraphQLError
): string | undefined => {
  const extensions = graphqlError.extensions;

  if (extensions && typeof extensions[code_key] === "string") {
    const val: string = extensions[code_key];
    return val;
  }

  return undefined;
};

export const invalidIdOrPasswordErrorCodes = [
  "100000",
  "100001",
  "100002",
  "100003",
  "100004",
  "100005",
  "100006",
  "100007",
  "100008",
];

const noApolloAlertExtensionCodes = invalidIdOrPasswordErrorCodes;

export const getAppAlertMessage = (
  graphqlError: GraphQLError
): string | undefined => {
  const code = getExtensionsCode(graphqlError);
  if (!code) {
    return undefined;
  }

  if (noApolloAlertExtensionCodes.includes(code)) {
    return undefined;
  }

  return graphqlError.message;
};
