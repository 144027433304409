import styles from "./ContentHeader.module.scss";

type Props = {
  title: string;
  description: string;
  emittableModalButton: () => JSX.Element;
};

const ContentHeader = ({ title, description, emittableModalButton }: Props) => {
  return (
    <div className={styles.content_header}>
      <div className={styles.title_container}>
        <h2>{title}</h2>
        <span className={styles.emittableModalButton}>
          {emittableModalButton()}
        </span>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default ContentHeader;
