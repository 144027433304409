import { InviteSalonUserInput } from "gql/__generated__/graphql";
import SimpleInputLabel from "components/ui/SimpleInputLabel/SimpleInputLabel";
import { useForm } from "react-hook-form";
import { SalonUserForm, salonUserResolver } from "utils/forms/salonUser";
import ModalDialog from "components/ui/ModalDialog/ModalDialog";
import ModalHeader from "../../common/ModalHeader/ModalHeader";
import ModalButtons from "../../common/ModalButtons/ModalButtons";
import { useCallback, useEffect, useState } from "react";
import styles from "./InviteSalonUserModal.module.scss";
import RoleRadioGroup from "../RoleRadioGroup/RoleRadioGroup";

type Props = {
  inviteSalonUser: (input: InviteSalonUserInput) => Promise<void>;
};

type UseInviteSalonUserModalReturn = {
  render: () => JSX.Element;
  openModal: () => void;
};

const useInviteSalonUserModal = ({
  inviteSalonUser,
}: Props): UseInviteSalonUserModalReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const onModalOpenChange = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);
  const onClickCancel = () => {
    setIsOpen(false);
  };

  // form
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    // @ts-ignore
    resolver: salonUserResolver,
    defaultValues: {
      email: "",
      role: "MEMBER",
    },
  });
  const role = watch("role");
  const onSubmit = useCallback(
    async (data: SalonUserForm) => {
      if (!window.confirm("招待しますか？")) {
        return;
      }

      await inviteSalonUser({
        email: data.email,
        role: data.role ?? "MEMBER",
      }).then(() => {
        alert(
          "招待メールを送信しました。\n※届くまでに数十秒かかる場合があります。"
        );
        setIsOpen(false);
      });
    },
    [inviteSalonUser]
  );

  useEffect(() => {
    if (!isOpen) {
      // reset form
      reset();
      clearErrors(); // maybe not necessary
    }
  }, [isOpen, reset, clearErrors]);

  const render = useCallback(() => {
    return (
      <ModalDialog isOpen={isOpen} onOpenChange={onModalOpenChange}>
        <div className={styles.modal_content}>
          <ModalHeader title="招待するユーザーの情報を入力してください" />

          <form
            className={styles.form}
            onSubmit={
              // enum type check doesn't work properly
              // @ts-ignore
              handleSubmit(onSubmit)
            }
          >
            <SimpleInputLabel title={"メールアドレス"} optional={false} />
            <input
              className={styles.email_input}
              type="email"
              placeholder={"sample@example.com"}
              {...register("email")}
            />
            <div className={styles.warning}>{errors.email?.message}</div>

            <RoleRadioGroup
              // @ts-ignore
              value={role}
              onValueChange={(value) => setValue("role", value)}
            />
            <div className={styles.warning}>{errors.role?.message}</div>

            <div style={{ marginBottom: "20px" }}></div>

            <ModalButtons
              okText="招待"
              cancelText="キャンセル"
              onClickCancel={onClickCancel}
            />
          </form>
        </div>
      </ModalDialog>
    );
  }, [
    errors,
    handleSubmit,
    isOpen,
    onModalOpenChange,
    role,
    setValue,
    onSubmit,
    register,
  ]);

  return {
    render,
    openModal: onModalOpenChange,
  };
};

export default useInviteSalonUserModal;
