import { useUserPermission } from "components/provider/apollo/userPermission";
import ContentHeader from "../common/ContentHeader/ContentHeader";
import styles from "./UsersSetting.module.scss";
import EmitModalButton from "../common/EmitModalButton/EmitModalButton";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteSalonUserDocument,
  DeleteSalonUserMutation,
  DeleteSalonUserMutationVariables,
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  GetSalonUsersDocument,
  GetSalonUsersQuery,
  GetSalonUsersQueryVariables,
  InviteSalonUserDocument,
  InviteSalonUserInput,
  InviteSalonUserMutation,
  InviteSalonUserMutationVariables,
  SalonUserAllFieldFragment,
  SalonUserAllFieldFragmentDoc,
  SwitchSalonOwnerDocument,
  SwitchSalonOwnerMutation,
  SwitchSalonOwnerMutationVariables,
  UpdateSalonUserRoleDocument,
  UpdateSalonUserRoleMutation,
  UpdateSalonUserRoleMutationVariables,
} from "gql/__generated__/graphql";
import { getFragmentData } from "gql/__generated__";
import useInviteSalonUserModal from "./InviteSalonUserModal/InviteSalonUserModal";
import useUpdateSalonUserRoleModal from "./UpdateSalonUserRoleModal/UpdateSalonUserRoleModal";
import SalonUsersTableView from "./SalonUsersTableView/SalonUsersTableView";
import { inviteSalonUserButtonVisible } from "./SalonUsersTableView/SalonUserstableView.logic";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import MobileSalonUsersTableView from "./SalonUsersTableView/MobileSalonUsersTableView";

const UsersSetting = () => {
  const { isMobile } = useMediaQueryContext();
  // queries
  const { userPermission, refetch: refetchUserPermission } =
    useUserPermission();

  const { data, refetch } = useQuery<
    GetSalonUsersQuery,
    GetSalonUsersQueryVariables
  >(GetSalonUsersDocument, { fetchPolicy: "cache-first" });
  const salonUsers =
    getFragmentData(
      SalonUserAllFieldFragmentDoc,
      data?.salonUsers.salonUsers
    ) ?? [];

  const { data: meData } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    { fetchPolicy: "cache-first" }
  );
  const mySalonUser = getFragmentData(
    SalonUserAllFieldFragmentDoc,
    meData?.me.salonUser
  );

  // mutations
  const [inviteSalonUser] = useMutation<
    InviteSalonUserMutation,
    InviteSalonUserMutationVariables
  >(InviteSalonUserDocument);
  const [updateSalonUserRole] = useMutation<
    UpdateSalonUserRoleMutation,
    UpdateSalonUserRoleMutationVariables
  >(UpdateSalonUserRoleDocument);
  const [switchSalonOwner] = useMutation<
    SwitchSalonOwnerMutation,
    SwitchSalonOwnerMutationVariables
  >(SwitchSalonOwnerDocument);
  const [deleteSalonUser] = useMutation<
    DeleteSalonUserMutation,
    DeleteSalonUserMutationVariables
  >(DeleteSalonUserDocument);

  // FIXME: やっぱりconfirmないやつ欲しい
  // modals
  const {
    render: renderInviteSalonUserModal,
    openModal: openInviteSalonUserModal,
  } = useInviteSalonUserModal({
    inviteSalonUser: async (input: InviteSalonUserInput) => {
      return inviteSalonUser({
        variables: {
          input,
        },
      }).then(() => {
        refetch(); // refetch because new salon user will be added
      }); // cast a returning value to void
    },
  });
  const {
    render: renderUpdateSalonUserRoleModal,
    openModal: openUpdateSalonUserRoleModal,
  } = useUpdateSalonUserRoleModal({
    updateSalonUserRole: async (input) => {
      return updateSalonUserRole({
        variables: {
          input,
        },
      }).then(() => {}); // cast a returning value to void
    },
  });

  // onClick functions
  const onClickSwitchOwnerButton = (userId: string) => {
    if (!window.confirm("オーナーを変更しますか？")) {
      return;
    }
    switchSalonOwner({
      variables: {
        input: {
          userId,
        },
      },
    }).then(() => {
      refetch();
      refetchUserPermission(); // 自分のpermissionが変わるためrefetch
      alert("オーナーを変更しました。");
    });
  };

  const onClickReinviteSalonUserButton = (
    salonUser: SalonUserAllFieldFragment
  ) => {
    if (!window.confirm("招待メールを再送しますか？")) {
      return;
    }
    inviteSalonUser({
      variables: {
        input: {
          email: salonUser.email,
          role: salonUser.role,
        },
      },
    }).then(() => {
      alert(
        "招待メールを再送しました。\n※届くまでに数十秒かかる場合があります。"
      );
    });
  };

  const onClickDeleteSalonUserButton = (userId: string) => {
    if (!window.confirm("ユーザーを削除しますか？")) {
      return;
    }
    deleteSalonUser({
      variables: {
        input: {
          userId,
        },
      },
    }).then(() => {
      refetch(); // refetch because the user will be deleted
      alert("ユーザーを削除しました。");
    });
  };

  return (
    <div className={styles.users_setting}>
      {renderInviteSalonUserModal()}
      {renderUpdateSalonUserRoleModal()}
      <ContentHeader
        title="ユーザー"
        description="店舗に所属するユーザー一覧です。"
        emittableModalButton={() => (
          <EmitModalButton
            text={"招待"}
            onClick={openInviteSalonUserModal}
            hide={!inviteSalonUserButtonVisible(userPermission)}
          />
        )}
      />
      {mySalonUser && userPermission && !isMobile && (
        <SalonUsersTableView
          mySalonUser={mySalonUser}
          salonUsers={salonUsers}
          userPermission={userPermission}
          openUpdateSalonUserRoleModal={openUpdateSalonUserRoleModal}
          onClickReinviteSalonUserButton={onClickReinviteSalonUserButton}
          onClickSwitchOwnerButton={onClickSwitchOwnerButton}
          onClickDeleteSalonUserButton={onClickDeleteSalonUserButton}
        />
      )}
      {mySalonUser && userPermission && isMobile && (
        <MobileSalonUsersTableView
          mySalonUser={mySalonUser}
          salonUsers={salonUsers}
          userPermission={userPermission}
          openUpdateSalonUserRoleModal={openUpdateSalonUserRoleModal}
          onClickReinviteSalonUserButton={onClickReinviteSalonUserButton}
          onClickSwitchOwnerButton={onClickSwitchOwnerButton}
          onClickDeleteSalonUserButton={onClickDeleteSalonUserButton}
        />
      )}
    </div>
  );
};

export default UsersSetting;
