export const isValidPhoneNumber = (phoneNumber?: string): boolean => {
  if (!phoneNumber) {
    return false;
  }
  return isValidJapanDomesticPhoneNumber(phoneNumber);
};

// 0xxxxxxxxxx
const isValidJapanDomesticPhoneNumber = (phoneNumber: string): boolean => {
  if (phoneNumber.length !== 11) {
    return false;
  }
  if (phoneNumber[0] !== "0") {
    return false;
  }
  if (!/^\d+$/.test(phoneNumber)) {
    return false;
  }
  return true;
};
