import { Salon, SalonNameFragment } from "gql/__generated__/graphql";
import styles from "./SalonSelect.module.scss";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";

type Props = {
  salons: readonly SalonNameFragment[]; // first salon is selected one
  onChangeSalon: (id: number) => void;
};

const SalonSelect = ({ salons, onChangeSalon }: Props) => {
  const [isOpen, setIsOpen] = useState(false); // なぜかスマホ実機の時だけ開いたらすぐ閉じちゃうので、明示的にstate定義してみる
  const [searchedSalons, setSearchedSalons] = useState<
    readonly SalonNameFragment[]
  >([]);

  useEffect(() => {
    setSearchedSalons(salons);
  }, [salons]);

  if (salons.length === 0) {
    // NOTE: hiddenで場所は確保しておいていいかも
    return <div></div>;
  }

  const selected = salons[0];
  const nonSelectedOptionExists = salons.length > 1;
  const onClick = (salonId: number) => {
    if (salonId === selected.id) {
      return;
    }
    onChangeSalon(salonId);
  };
  const onSearch = (searchText: string) => {
    if (searchText === "") {
      setSearchedSalons(salons);
    }
    const lowercaseSearchText = searchText.toLowerCase();

    const searched = salons.filter(
      (salon) =>
        salon.name.toLowerCase().includes(lowercaseSearchText) ||
        salon.displayName?.toLowerCase().includes(lowercaseSearchText)
    );
    setSearchedSalons(searched);
  };
  const getSalonDisplayName = (salon: SalonNameFragment) =>
    salon.displayName ? salon.displayName : salon.name;

  if (!nonSelectedOptionExists) {
    // popoverをそもそも出さない
    return (
      <div className={styles.title_when_no_other_salon}>
        {getSalonDisplayName(selected)}
      </div>
    );
  }

  // radix uiのpopoverで実装して、↓のPRをいれた瞬間、なぜかスマホ実機の時だけ開くときとじてしまうようになった。なので一旦naiveに実装
  // https://github.com/SouhlInc/refund-hub-frontend/pull/79/commits/494858113e81960cba59fa02e2dc9738aee88e52#diff-76d1729da0bf7005c303fba1b230fcc3c43d42d83a190a6bff21ed12dfcfbc4c
  return (
    <div>
      {isOpen && (
        <div className={styles.overlay} onClick={() => setIsOpen(false)}></div>
      )}
      <div className={styles.container}>
        <button className={styles.trigger} onClick={() => setIsOpen(!isOpen)}>
          <span className={styles.icon}>
            <CaretSortIcon />
          </span>
          <span>{getSalonDisplayName(selected)}</span>
        </button>
        {isOpen && (
          <div className={styles.content}>
            <input
              className={styles.item}
              placeholder="店舗名を検索"
              onChange={(e) => onSearch(e.target.value)}
            />
            {searchedSalons.map((salon) => (
              <div
                className={styles.item}
                key={salon.id}
                onClick={(e) => {
                  e.preventDefault();
                  onClick(salon.id);
                }}
              >
                <CheckIcon
                  style={{
                    visibility: salon.id === selected.id ? "visible" : "hidden",
                  }}
                />
                {getSalonDisplayName(salon)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SalonSelect;
