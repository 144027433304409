import styles from "./CouponMasterNewPage.module.scss";
import { useForm } from "react-hook-form";
import {
  CouponMasterForm,
  couponMasterResolver,
} from "utils/forms/couponMaster";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CreateCouponMasterDocument } from "gql/__generated__/graphql";
import { getCouponMasterName } from "domain/coupon";
import { useMemo } from "react";

const CouponMasterNewPage = () => {
  const navigate = useNavigate();
  const {
    register,
    watch,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: couponMasterResolver,
    defaultValues: {
      targetName: "施術代",
      discountType: "fixed",
      discountAmount: 1000,
      discountPercent: undefined,
      expireDurationDay: 90,
      minAmount: 5000,
      isDefaultCouponMaster: false,
    },
  });
  const discountType = watch("discountType");
  const discountAmount = watch("discountAmount");
  const discountPercent = watch("discountPercent");
  const couponMasterName = getCouponMasterName(
    discountType === "fixed",
    discountAmount,
    discountPercent
  );
  const discountPercentText = useMemo(() => {
    let text = "0";
    if (discountPercent) {
      text = discountPercent.toLocaleString();
    }
    return text;
  }, [discountPercent]);
  const [mutate] = useMutation(CreateCouponMasterDocument);

  const onSubmit = async (data: CouponMasterForm) => {
    let discountAmount: number | undefined = undefined;
    let discountPercent: number | undefined = undefined;
    // TODO: 型はnumber | undefinedとなっているがstringでなぜか返ってくるので、何かがおかしい
    if (data.discountType === "fixed") {
      discountAmount = Number(getValues("discountAmount"));
    } else {
      discountPercent = Number(getValues("discountPercent"));
    }
    await mutate({
      variables: {
        input: {
          name: couponMasterName,
          targetName: data.targetName,
          discountAmount: discountAmount,
          discountPercent: discountPercent,
          expireDurationDay: data.expireDurationDay,
          minAmount: data.minAmount,
          isDefaultCouponMaster: data.isDefaultCouponMaster,
        },
      },
    });
    alert("特典を作成しました。");
    navigate("/coupon_masters");
  };
  const onClickFixed = () => {
    setValue("discountType", "fixed");
    clearErrors("discountPercent");
  };
  const onClickPercentage = () => {
    setValue("discountType", "percentage");
    clearErrors("discountAmount");
  };
  return (
    <div className={styles.coupon_master}>
      <a href="#" className={styles.return} onClick={() => navigate(-1)}>
        一覧へ戻る
      </a>

      <div className={styles.title}>
        <h1>特典の作成</h1>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label>【１】クーポン名</label>
        <p className={styles.coupon_master_name}>{couponMasterName}</p>

        <label>【２】割引対象</label>
        <input type="text" placeholder="施術代" {...register("targetName")} />
        <div className={styles.warning}>{errors.targetName?.message}</div>

        {/* <label>【３】割引方法を選択</label> */}
        <label>【３】割引方法</label>
        <div>
          <input
            type="radio"
            value="fixed"
            checked={discountType === "fixed"}
            {...(register("discountType"),
            { name: "discountType", onClick: onClickFixed })}
          />
          <label className={styles.radio_label} onClick={onClickFixed}>
            定額割引
          </label>
        </div>
        {discountType === "fixed" && (
          <input
            type="number"
            placeholder="1,000"
            {...register("discountAmount")}
          />
        )}
        <div className={styles.warning}>{errors.discountAmount?.message}</div>
        <div>
          <input
            type="radio"
            value="percentage"
            checked={discountType === "percentage"}
            {...(register("discountType"),
            {
              name: "discountType",
              onClick: onClickPercentage,
            })}
          />
          <label className={styles.radio_label} onClick={onClickPercentage}>
            パーセント割引 (二桁円以下切り捨て)
          </label>
        </div>
        {discountType === "percentage" && (
          <input
            type="number"
            placeholder="30"
            {...register("discountPercent")}
          />
        )}
        <div className={styles.warning}>{errors.discountPercent?.message}</div>

        <label>【４】有効期限（支払い日から有効な日数）</label>
        <input
          type="number"
          placeholder="60"
          {...register("expireDurationDay")}
        />
        <div className={styles.warning}>
          {errors.expireDurationDay?.message}
        </div>

        <label>【５】最低利用金額（￥）</label>
        <input type="number" placeholder="5,000" {...register("minAmount")} />
        <div className={styles.warning}>{errors.minAmount?.message}</div>

        <div
          className={styles.is_default_checkbox_container}
          onClick={() =>
            setValue(
              "isDefaultCouponMaster",
              !getValues("isDefaultCouponMaster")
            )
          }
        >
          <input type="checkbox" {...register("isDefaultCouponMaster")} />
          <span className={styles.checkbox_label}>
            デフォルトでこのクーポンを付与する
          </span>
        </div>

        <button className={`common-button ${styles.coupon_button}`}>
          作成する
        </button>
      </form>
    </div>
  );
};

export default CouponMasterNewPage;
