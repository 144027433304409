export const PasswordRequirements = () => {
  return (
    <p>
      新しいパスワードを入力してください。
      <br />
      <br />
      パスワード要件
      <br />
      8 文字以上
      <br />
      少なくとも 1 つの数字を含む
      <br />
      少なくとも 1 つの大文字を含む
      <br />
      少なくとも 1 つの小文字を含む
    </p>
  );
};
