export const getCouponMasterName = (
  isDiscountAmount: boolean,
  discountAmount?: number,
  discountPercent?: number
): string => {
  if (isDiscountAmount) {
    let amount = "0";
    if (discountAmount) {
      amount = discountAmount.toLocaleString();
    }
    return amount + "円OFFクーポン";
  } else if (!isDiscountAmount) {
    let amount = "0";
    if (discountPercent) {
      amount = discountPercent.toLocaleString();
    }
    return amount + "%OFFクーポン";
  } else {
    return "";
  }
};
