import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { requiredMessage } from "../errorMessage";

export type SalonDetailForm = yup.Asserts<typeof salonDetailScheme>;

export const salonDetailScheme = yup.object({
  salonName: yup.string().required(requiredMessage("店舗名")),
  salonDisplayName: yup
    .string()
    .required(requiredMessage("店舗名 (サイドバー)")),
  salonAddress: yup.string().required(requiredMessage("店舗住所")),
});

export const salonDetailResolver = yupResolver(salonDetailScheme);
