import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  lessThanMessage,
  moreThanMessage,
  requiredMessage,
} from "../errorMessage";

export type CouponMasterForm = yup.Asserts<typeof couponMasterScheme>;

// ぶっちゃけdiscountTypeはここに入れなくてよかったんじゃねと思っている
export const couponMasterScheme = yup.object({
  targetName: yup.string().required("割引対象は必須です"),
  discountType: yup
    .string()
    .required(requiredMessage("割引方法"))
    .oneOf(["fixed", "percentage"], "割引方法が不正です"),
  discountAmount: yup
    .number()
    .transform((v) => (isNaN(v) ? undefined : v)) // 空文字だとNaNになるので、その時はundefinedにする。
    .when("discountType", {
      is: (discountType: string) => discountType === "fixed",
      then: (schema) =>
        schema
          .required(moreThanMessage(1)) // error when undefined
          .positive(moreThanMessage(1)),
    }),
  discountPercent: yup
    .number()
    .transform((v) => (isNaN(v) ? undefined : v))
    .when("discountType", {
      is: (discountType: string) => discountType === "percentage",
      then: (schema) =>
        schema
          .required(moreThanMessage(1))
          .min(1, moreThanMessage(1))
          .max(100, lessThanMessage(100)),
    }),
  expireDurationDay: yup
    .number()
    .transform((v) => (isNaN(v) ? undefined : v))
    .required(moreThanMessage(1))
    .positive(moreThanMessage(1))
    .max(365, lessThanMessage(365)),
  minAmount: yup
    .number()
    .transform((v) => (isNaN(v) ? undefined : v))
    .required(moreThanMessage(0))
    .min(0, moreThanMessage(0)),
  isDefaultCouponMaster: yup
    .boolean()
    .required(requiredMessage("デフォルトでこのクーポンを付与する"))
    .default(false),
});

export const couponMasterResolver = yupResolver(couponMasterScheme);
