import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { useCallback, useMemo } from "react";

export type ReactiveVarHooks<T> = [T, (payload: T) => void];
export const useReactiveVarHooks = <T>(
  reactiveVar: ReactiveVar<T>
): ReactiveVarHooks<T> => {
  const value = useReactiveVar(reactiveVar);
  const setValue = useCallback(
    (payload: T) => {
      reactiveVar(payload);
    },
    [reactiveVar]
  );
  return useMemo(() => [value, setValue], [setValue, value]);
};
