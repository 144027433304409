import { shouldUseDefaultCouponMaster } from "domain/home";
import { getFragmentData } from "gql/__generated__";
import {
  CancelPaymentStatus,
  CancelForListFragment,
  CouponMasterNameFragment,
  CouponMasterNameFragmentDoc,
  CancelRereservationStatus,
  CancelForRereservationFragment,
} from "gql/__generated__/graphql";
import _ from "lodash";
import { EditingRow, Tab } from "../HomePage.logic";
import QuestionExplanationIcon from "components/ui/QuetionExplanationIcon/QuestionExplanationIcon";

export const STATUS: Record<CancelPaymentStatus, string> = {
  INVOICE_NOT_SENT: "未請求",
  INVOICE_SENT: "請求済",
  PAID: "回収済",
  NOT_SUBJECT: "非請求",
  CLAIM_ABORTED: "取消済",
  SCHEDULED_TO_CLAIM: "請求予定",
};

export const showUpdatePaymentStatusButton = (
  currentTab: Tab,
  checkedCancelMap: Record<number, CancelForListFragment>
): boolean => {
  if (currentTab === "INVOICE_NOT_SENT" || currentTab === "NOT_SUBJECT") {
    return _.size(checkedCancelMap) > 0;
  }
  return false;
};

export const isEditablePaymentStatus = (
  paymentStatus: CancelPaymentStatus
): boolean => {
  return paymentStatus === "INVOICE_NOT_SENT";
};

export const showCheckbox = (paymentStatus: CancelPaymentStatus) => {
  return (
    paymentStatus === "INVOICE_NOT_SENT" || paymentStatus === "NOT_SUBJECT"
  );
};

export const updatePaymentStatusButtonTitle = (paymentStatus: Tab) => {
  switch (paymentStatus) {
    case "INVOICE_NOT_SENT":
      return "非請求タブへ移す";
    case "NOT_SUBJECT":
      return "未請求タブへ移す";
    default:
      return "";
  }
};

export const getCouponMasterSelectDefaultValue = (
  currentTab: Tab,
  cancelRow: CancelForListFragment,
  enableDefaultCouponMasterForNoConfirmationCancel: boolean,
  defaultCouponMaster?: CouponMasterNameFragment
): CouponMasterNameFragment | undefined => {
  if (
    shouldUseDefaultCouponMaster(
      currentTab,
      cancelRow,
      enableDefaultCouponMasterForNoConfirmationCancel,
      defaultCouponMaster
    )
  ) {
    return defaultCouponMaster;
  }

  return (
    getFragmentData(CouponMasterNameFragmentDoc, cancelRow.couponMaster) ??
    undefined
  );
};

export const getInvoicePrice = (
  row: CancelForListFragment,
  isEditingRow: boolean,
  editingRowMap: Record<string, EditingRow>
) => {
  if (isEditingRow) {
    return editingRowMap[row.idv2].invoicePrice;
  }
  return row.invoicePrice;
};

export const getReserveId = (
  row: CancelForListFragment | CancelForRereservationFragment
) => {
  if (row.isCreatedByUi) {
    return "手動";
  }
  return row.reserveId;
};

export const renderVisitCount = (
  row: CancelForListFragment | CancelForRereservationFragment
) => {
  if (row.isVisitCountInvalid) {
    return (
      <span>
        不明{" "}
        <QuestionExplanationIcon>
          <div>
            <p>来店回数が特定できません。</p>
            <p>
              複数の予約管理媒体間の顧客の突合ができない場合等に発生します。
            </p>
          </div>
        </QuestionExplanationIcon>
      </span>
    );
  }
  return <span>{row.visitCounts}</span>;
};
