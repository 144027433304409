import QuestionExplanationIcon from "../QuetionExplanationIcon/QuestionExplanationIcon";
import styles from "./SimpleAttributeRow.module.scss";

type Props = {
  label: string;
  questionPopoverMessage?: string;
  questionExplanationJSX?: () => JSX.Element;
  content: string | (() => JSX.Element);
};

export default function SimpleAttributeRow({
  label,
  questionPopoverMessage,
  questionExplanationJSX,
  content,
}: Props) {
  return (
    <div className={styles.attribute}>
      <div className={styles.label}>
        {label}
        {(questionPopoverMessage || questionExplanationJSX) && (
          <QuestionExplanationIcon contentMessage={questionPopoverMessage}>
            {questionExplanationJSX && questionExplanationJSX()}
          </QuestionExplanationIcon>
        )}
      </div>
      <div className={styles.content}>
        {typeof content === "string" ? content : content()}
      </div>
    </div>
  );
}
