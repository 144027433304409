import {
  CancelForRereservationFragment,
  CancelRereservationStatus,
} from "gql/__generated__/graphql";

export const canEdit = (
  rereservationStatus: CancelRereservationStatus
): boolean => {
  return rereservationStatus === "ENCOURAGING_RERESERVATION_NOT_SCHEDULED";
};

export const rereservationStatusText: Record<
  CancelRereservationStatus,
  string
> = {
  ENCOURAGING_RERESERVATION_NOT_SCHEDULED: "送付情報不足",
  ENCOURAGING_RERESERVATION_SCHEDULED: "送付予定",
  ENCOURAGING_MESSAGE_SENT: "送付済",
  RERESERVED: "再予約済",
  RERESERVED_BY_REFUND_HUB: "再予約済",
  // RERESERVED_BY_OTHER_THAN_REFUND_HUB: "再予約済 (RefundHub以外)", # FIXME:
  RERESERVED_BY_OTHER_THAN_REFUND_HUB: "再予約済",
};

export const getSpanClass = (row: CancelForRereservationFragment, styles) => {
  let spanClass = "";
  if (row.rereservationStatus === "ENCOURAGING_RERESERVATION_NOT_SCHEDULED")
    spanClass = styles.background_light_orange;
  else if (row.rereservationStatus === "ENCOURAGING_RERESERVATION_SCHEDULED")
    spanClass = styles.background_light_green;
  else if (row.rereservationStatus === "ENCOURAGING_MESSAGE_SENT") {
    spanClass = styles.background_pink;
  } else if (
    row.rereservationStatus === "RERESERVED_BY_REFUND_HUB" ||
    row.rereservationStatus === "RERESERVED_BY_OTHER_THAN_REFUND_HUB"
  )
    spanClass = styles.background_blue;

  return spanClass;
};
