import styles from "./InvalidLink.module.scss";

const InvalidLink = () => {
  return (
    <div className={styles.invalid_link}>
      <div>無効な招待リンクです。</div>
      <a href="/">ログイン画面にもどる。</a>
    </div>
  );
};

export default InvalidLink;
