export type FetchFunc = typeof fetch;
export type FetchMiddleware = (next: FetchFunc) => FetchFunc;

export function wrapFetch(
  originalFetch: typeof fetch,
  /** 配列の先頭の要素が外側になる */
  middlewares: FetchMiddleware[]
): typeof fetch {
  let wrappedFetch: FetchFunc = originalFetch;

  for (let i = middlewares.length - 1; i >= 0; i--) {
    const middleware = middlewares[i]!;
    wrappedFetch = middleware(wrappedFetch);
  }

  return (input, init) => wrappedFetch(input, init);
}

export const withCallback = (callback: () => () => void): FetchMiddleware => {
  return (next) => (input, init) => {
    const done = callback();
    return next(input, init).finally(done);
  };
};

export function withHeaders(
  additionalHeaders: Readonly<Record<string, string>>
): FetchMiddleware {
  return (next) => (input, init) => {
    const headers = new Headers(init.headers);
    for (const [k, v] of Object.entries(additionalHeaders)) {
      headers.set(k, v);
    }
    return next(input, { ...init, headers });
  };
}

// export function withHandleStatusCode(
//   statusCode: number,
//   handler: (
//     req: { input: string | URL; init: RequestInit },
//     res: Response,
//     next: FetchFunc
//   ) => Promise<Response> | Response
// ): FetchMiddleware {
//   return (next) => async (input, init) => {
//     const res = await next(input, init);
//     return res.status === statusCode
//       ? handler({ input, init }, res, next)
//       : res;
//   };
// }
