import {
  ApolloClient,
  ApolloQueryResult,
  InMemoryCache,
  createHttpLink,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { fetchAuthSession } from "aws-amplify/auth";
import { getFragmentData } from "gql/__generated__";
import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  SalonAllFieldFragmentDoc,
} from "gql/__generated__/graphql";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLoading } from "../loading/useLoading";

type SalonContextType = {
  currentSalonID: number;
};
const SalonContext = createContext<SalonContextType | undefined>(undefined);

const authLinkForGetMe = setContext(async (_, { headers }) => {
  const session = await fetchAuthSession();
  const token = session.tokens?.accessToken.toString() ?? "";

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const apolloClientForGetMe = new ApolloClient({
  // 重複コード三箇所くらいあるので、カスタムフック作るべきかも。
  link: authLinkForGetMe.concat(
    createHttpLink({
      uri: process.env.REACT_APP_API_URL + "/graphql",
    })
  ),
  cache: new InMemoryCache(),
});

export const SalonProvider = ({
  children,
}: {
  children: (props: {
    currentSalonID: number;
    currentUserID: string;
  }) => ReactNode;
}) => {
  const { startLoading } = useLoading();
  const [data, setData] = useState<GetMeQuery | null>(null);
  const currentSalonID = getFragmentData(
    SalonAllFieldFragmentDoc,
    data?.me.salon
  )?.id;

  const [currentUserID, setCurrentUserID] = useState<string | null>(null);

  const ctx = useMemo(
    () => ({ currentSalonID: currentSalonID ?? 0 }), // 0 is dummy, but no problem because this component will return null
    [currentSalonID]
  );

  useEffect(() => {
    const stopLoading = startLoading();
    fetchAuthSession()
      .then((session) => {
        setCurrentUserID(session.tokens?.idToken?.payload.sub ?? null);
      })
      .finally(stopLoading);
  }, [startLoading]);
  useEffect(() => {
    const stopLoading = startLoading();
    apolloClientForGetMe
      .query<GetMeQuery, GetMeQueryVariables>({
        fetchPolicy: "no-cache",
        query: GetMeDocument,
      })
      .then((result) => {
        setData(result.data);
      })
      .finally(stopLoading);
  }, [startLoading]);

  if (!currentSalonID || !currentUserID) {
    return null;
  }

  return (
    <SalonContext.Provider value={ctx}>
      {children({ currentSalonID, currentUserID })}
    </SalonContext.Provider>
  );
};

export const useSalon = () => {
  const context = useContext(SalonContext);
  if (context === undefined) {
    throw new Error("useContext must be used within a SalonProvider");
  }
  return context;
};
