import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import styles from "./ActionDropdownMenu.module.scss";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import { useUserPermission } from "components/provider/apollo/userPermission";
import { useMemo } from "react";

type Props = {
  isActive: boolean;
  isDefaultCouponMaster: boolean;
  onClickUpdateStatusButton: () => void;
  onClickDefaultCouponMasterButton: () => void;
};

const ActionDropdownMenu = ({
  isActive,
  isDefaultCouponMaster,
  onClickUpdateStatusButton,
  onClickDefaultCouponMasterButton,
}: Props) => {
  const { isMobile } = useMediaQueryContext();
  const { userPermission } = useUserPermission();
  const showActivateOrDeactivateButton = useMemo(() => {
    if (isActive) {
      return userPermission?.canDeactivateCouponMaster ?? false;
    }
    return userPermission?.canActivateCouponMaster ?? false;
  }, [isActive, userPermission]);
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={styles.trigger}>
        {isMobile ? <div>アクション</div> : <HamburgerMenuIcon />}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.dropdown_content}>
          <DropdownMenu.Arrow className={styles.arrow} />
          {isActive && (
            <div>
              <DropdownMenu.Item>
                <button
                  onClick={onClickDefaultCouponMasterButton}
                  style={{
                    visibility:
                      userPermission?.canUpdateDefaultCouponMasterSetting
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {isDefaultCouponMaster
                    ? "デフォルトで付与するのをやめる"
                    : "デフォルトでこのクーポンを付与する"}
                </button>
              </DropdownMenu.Item>
              <DropdownMenu.Separator className={styles.separator} />
            </div>
          )}
          <DropdownMenu.Item>
            <button
              style={{
                visibility: showActivateOrDeactivateButton
                  ? "visible"
                  : "hidden",
              }}
              onClick={() => onClickUpdateStatusButton()}
            >
              {isActive ? "選択肢から非表示にする" : "選択肢に表示する"}
            </button>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default ActionDropdownMenu;
