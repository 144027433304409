import { useMutation, useQuery } from "@apollo/client";
import { useClaimRuleText } from "components/provider/apollo/reactiveVars/useClaimRuleText";
import {
  GetClaimRuleQuery,
  GetClaimRuleQueryVariables,
  UpsertClaimRuleMutation,
  UpsertClaimRuleMutationVariables,
} from "gql/__generated__/graphql";
import { GetClaimRule, UpsertClaimRule } from "gql/query";
import { useEffect } from "react";
import styles from "./ClaimRulePage.module.scss";
import QuestionExplanationIcon from "components/ui/QuetionExplanationIcon/QuestionExplanationIcon";
import { useUserPermission } from "components/provider/apollo/userPermission";

const ClaimRuleExplainMessage = () => {
  return (
    <div>
      <div>請求ルールを、スタッフ向けにメモすることができます。</div>
    </div>
  );
};

const ClaimRulePage = () => {
  const { data, loading } = useQuery<
    GetClaimRuleQuery,
    GetClaimRuleQueryVariables
  >(GetClaimRule, {
    fetchPolicy: "cache-first",
  });
  const {
    value,
    setDefaultTextByQuery,
    onClickStartEdit,
    onClickCancelEdit,
    onChangeText,
  } = useClaimRuleText();
  const [mutate] = useMutation<
    UpsertClaimRuleMutation,
    UpsertClaimRuleMutationVariables
  >(UpsertClaimRule);
  const { userPermission } = useUserPermission();

  const onClickMainButton = async () => {
    if (value.isEditing) {
      if (window.confirm("変更を保存しますか？")) {
        await mutate({
          variables: {
            input: {
              text: value.text,
            },
          },
        }).then(() => {
          // auto cache update happens here by apollo client
          // https://www.apollographql.com/docs/react/v2/data/mutations/#updating-the-cache-after-a-mutation

          alert("請求送信の基準を更新しました");
        });
      }
    } else {
      onClickStartEdit();
    }
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    setDefaultTextByQuery(data?.claimRule?.text ?? "");
  }, [loading, data, setDefaultTextByQuery]);

  return (
    <div className={styles.claim_rule}>
      <div className={styles.top_content}>
        <h1>請求ルール</h1>
        <QuestionExplanationIcon>
          <ClaimRuleExplainMessage />
        </QuestionExplanationIcon>
        {/* enableする予定
        {!isMobile && (
          <button
            className={styles.location_reload_button}
            onClick={() => {
              window.location.reload();
            }}
          >
            リロード
          </button>
        )} */}
      </div>

      <div className={styles.main_content}>
        <div
          style={{ visibility: value.isEditing ? "visible" : "hidden" }}
          className={styles.char_count}
        >
          {value.charCount}/1000文字
        </div>
        <textarea
          value={value.text}
          onChange={(e) => onChangeText(e.target.value)}
          disabled={!value.isEditing}
          maxLength={1000}
        />
        <div className={styles.button_container}>
          {value.isEditing && (
            <button
              className={styles.cancel_button}
              onClick={() => onClickCancelEdit()}
            >
              キャンセル
            </button>
          )}
          <button
            style={{
              backgroundColor:
                value.isEditing && !value.isChanged ? "#d1d1d1" : "#ff8f4f",
              visibility: userPermission?.canUpsertClaimRule
                ? "visible"
                : "hidden",
            }}
            disabled={value.isEditing && !value.isChanged}
            className={styles.main_button}
            onClick={() => onClickMainButton()}
          >
            {value.isEditing ? "更新する" : "編集する"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClaimRulePage;
