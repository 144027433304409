import { useMutation, useQuery } from "@apollo/client";
import { getFragmentData } from "gql/__generated__/fragment-masking";
import * as Tabs from "@radix-ui/react-tabs";
import styles from "./CouponMastersPage.module.scss";

import {
  CouponMasterForListFragment,
  CouponMasterForListFragmentDoc,
  CouponMasterNameFragmentDoc,
  DeactivateCouponMasterV2Document,
  GetCouponMastersDocument,
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  SalonAllFieldFragmentDoc,
  UpdateDefaultCouponMasterSettingDocument,
} from "gql/__generated__/graphql";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import MobileCouponMastersTableView from "./CouponMastersTableView/MobileCouponMastersTableView";
import CouponMastersTableView from "./CouponMastersTableView/CouponMastersTableView";
import {
  ActivateCouponMaster,
  DeactivateCouponMaster,
  UpdateDefaultCouponMasterSetting,
} from "gql/query";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import {
  confirmActivateCouponMasterMessage,
  confirmDeactivateCouponMasterMessage,
  isDefaultCouponMaster,
} from "./CouponMasterPage.logic";
import QuestionExplanationIcon from "components/ui/QuetionExplanationIcon/QuestionExplanationIcon";
import { useUserPermission } from "components/provider/apollo/userPermission";
import { useCallback, useMemo } from "react";

enum Tab {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

const CouponMasterExplainMessage = () => {
  return (
    <div>
      <div>
        キャンセル料支払い後に、送信される特典を設定することができます。
      </div>
      <div>
        「選択肢に表示」にある特典のみ、キャンセル画面で選択肢に表示されます。
      </div>
    </div>
  );
};

const CouponMastersPage = () => {
  const { isMobile } = useMediaQueryContext();
  const navigate = useNavigate();
  const {
    data: activeCouponMastersData,
    refetch: refetchActiveCouponMastersData,
  } = useQuery(GetCouponMastersDocument, {
    variables: {
      param: {
        isActive: true,
      },
    },
    fetchPolicy: "no-cache",
  });
  const activeCouponMasters = getFragmentData(
    CouponMasterForListFragmentDoc,
    activeCouponMastersData?.couponMasters
  );

  const {
    data: inactiveCouponMastersData,
    refetch: refetchInactiveCouponMastersData,
  } = useQuery(GetCouponMastersDocument, {
    variables: {
      param: {
        isActive: false,
      },
    },
  });
  const inactiveCouponMasters = getFragmentData(
    CouponMasterForListFragmentDoc,
    inactiveCouponMastersData?.couponMasters
  );

  const { data: meData } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    { fetchPolicy: "cache-first" }
  );
  const salon = getFragmentData(SalonAllFieldFragmentDoc, meData?.me.salon);
  const defaultCouponMaster =
    getFragmentData(CouponMasterNameFragmentDoc, salon?.defaultCouponMaster) ??
    undefined;
  const enableDefaultCouponMasterForNoConfirmationCancel =
    salon?.enableDefaultCouponMasterForNoConfirmationCancel ?? false;

  const [activateCouponMaster] = useMutation(ActivateCouponMaster);
  const [deactivateCouponMaster] = useMutation(
    DeactivateCouponMasterV2Document
  );
  const [updateDefaultCouponMasterSetting] = useMutation(
    UpdateDefaultCouponMasterSettingDocument
  );

  const { userPermission } = useUserPermission();
  const showActionButton = useCallback(
    (isActive: boolean) => {
      if (!userPermission) {
        return false;
      }

      if (isActive) {
        return (
          userPermission?.canDeactivateCouponMaster ||
          userPermission?.canUpdateDefaultCouponMasterSetting
        );
      } else {
        return userPermission?.canActivateCouponMaster;
      }
    },
    [userPermission]
  );

  const onClickUpdateStatusButton = async (
    isActive: boolean,
    row: CouponMasterForListFragment
  ) => {
    if (isActive) {
      if (!window.confirm(confirmDeactivateCouponMasterMessage())) {
        return;
      }
      await deactivateCouponMaster({
        variables: {
          input: {
            id: row.id,
          },
        },
      });
    } else {
      if (!window.confirm(confirmActivateCouponMasterMessage)) {
        return;
      }
      await activateCouponMaster({
        variables: {
          input: {
            id: row.id,
          },
        },
      });
    }
    refetchActiveCouponMastersData();
    refetchInactiveCouponMastersData();
    alert("更新完了しました。");
  };

  const onClickDefaultCouponMasterButton = async (
    row: CouponMasterForListFragment
  ) => {
    let confirmMessage = "デフォルトでこのクーポンを付与するよう変更しますか?";
    let defaultCouponMasterId: string | null = row.id;
    if (isDefaultCouponMaster(row, defaultCouponMaster)) {
      confirmMessage = "デフォルトでこのクーポンを付与しないよう変更しますか?";
      defaultCouponMasterId = null;
    }

    if (!window.confirm(confirmMessage)) {
      return;
    }

    await updateDefaultCouponMasterSetting({
      variables: {
        input: {
          couponMasterId: defaultCouponMasterId,
          enableDefaultCouponMasterForNoConfirmationCancel,
        },
      },
    }).then(() => {
      alert("更新完了しました。");
    });
  };

  const onTabChange = (tab: string) => {
    if (tab === Tab.ACTIVE) {
      refetchActiveCouponMastersData();
    } else if (tab === Tab.INACTIVE) {
      refetchInactiveCouponMastersData();
    }
  };

  return (
    <div className={styles.coupon_masters}>
      <div className={styles.top_content}>
        <h1>特典一覧</h1>
        <QuestionExplanationIcon>
          <CouponMasterExplainMessage />
        </QuestionExplanationIcon>

        {/* {!isMobile && (
          <span className={styles.switch_container}>
            <label>デフォルトのクーポンを無断キャンセルにも付与する</label>
            <Switch.Root className={styles.switch_root}>
              <Switch.Thumb className={styles.switch_thumb} />
            </Switch.Root>
          </span>
        )} */}

        {/* enableする予定
        {!isMobile && (
          <button
            className={styles.location_reload_button}
            onClick={() => {
              window.location.reload();
            }}
          >
            リロード
          </button>
        )} */}
      </div>

      <div className={styles.main_content}>
        <Tabs.Root defaultValue={Tab.ACTIVE} onValueChange={onTabChange}>
          <Tabs.List className={styles.tabs_container}>
            <Tabs.Trigger value={Tab.ACTIVE} className={styles.tab}>
              <span className={styles.inline_block}>選択肢に</span>
              <span className={styles.inline_block}>表示</span>
            </Tabs.Trigger>
            <Tabs.Trigger value={Tab.INACTIVE} className={styles.tab}>
              非表示
            </Tabs.Trigger>

            <span
              className={styles.create_coupon_master_button_container}
              style={{
                visibility: userPermission?.canCreateCouponMaster
                  ? "visible"
                  : "hidden",
              }}
            >
              <button
                className={styles.create_coupon_master_button}
                onClick={() => navigate("/coupon_masters/new")}
              >
                <span className={styles.inline_block}>特典の</span>
                <span className={styles.inline_block}>
                  作成
                  <ArrowTopRightIcon />
                </span>
              </button>
            </span>
          </Tabs.List>

          {isMobile ? (
            <div>
              {/* <span className={styles.switch_container}>
                  <label style={{ fontSize: "0.86rem" }}>
                    % TODO: default check column, switch TODO: span.
                    9件の結果の横につける
                  </label>
                  <Switch.Root className={styles.switch_root}>
                    <Switch.Thumb className={styles.switch_thumb} />
                  </Switch.Root>
                </span> */}
              <Tabs.Content value={Tab.ACTIVE}>
                <MobileCouponMastersTableView
                  couponMasters={activeCouponMasters ?? undefined}
                  isActive
                  showActionButton={showActionButton(true)}
                  isDefaultCouponMaster={(row) =>
                    isDefaultCouponMaster(row, defaultCouponMaster)
                  }
                  onClickUpdateStatusButton={(row) =>
                    onClickUpdateStatusButton(true, row)
                  }
                  onClickDefaultCouponMasterButton={
                    onClickDefaultCouponMasterButton
                  }
                />
              </Tabs.Content>
              <Tabs.Content value={Tab.INACTIVE}>
                <MobileCouponMastersTableView
                  couponMasters={inactiveCouponMasters ?? undefined}
                  isActive={false}
                  showActionButton={showActionButton(false)}
                  isDefaultCouponMaster={(row) =>
                    isDefaultCouponMaster(row, defaultCouponMaster)
                  }
                  onClickUpdateStatusButton={(row) =>
                    onClickUpdateStatusButton(false, row)
                  }
                  onClickDefaultCouponMasterButton={
                    onClickDefaultCouponMasterButton
                  }
                />
              </Tabs.Content>
            </div>
          ) : (
            <div>
              <Tabs.Content value={Tab.ACTIVE}>
                <CouponMastersTableView
                  couponMasters={activeCouponMasters ?? undefined}
                  isActive
                  showActionButton={showActionButton(true)}
                  isDefaultCouponMaster={(row) =>
                    isDefaultCouponMaster(row, defaultCouponMaster)
                  }
                  onClickUpdateStatusButton={(row) =>
                    onClickUpdateStatusButton(true, row)
                  }
                  onClickDefaultCouponMasterButton={
                    onClickDefaultCouponMasterButton
                  }
                />
              </Tabs.Content>
              <Tabs.Content value={Tab.INACTIVE}>
                <CouponMastersTableView
                  couponMasters={inactiveCouponMasters ?? undefined}
                  isActive={false}
                  showActionButton={showActionButton(false)}
                  isDefaultCouponMaster={(row) =>
                    isDefaultCouponMaster(row, defaultCouponMaster)
                  }
                  onClickUpdateStatusButton={(row) =>
                    onClickUpdateStatusButton(false, row)
                  }
                  onClickDefaultCouponMasterButton={
                    onClickDefaultCouponMasterButton
                  }
                />
              </Tabs.Content>
            </div>
          )}
        </Tabs.Root>
      </div>
    </div>
  );
};

export default CouponMastersPage;
