import * as HoverCard from "@radix-ui/react-hover-card";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import styles from "./QuestionHoverCard.module.scss";
import { Slot } from "@radix-ui/react-slot";

type Props = {
  contentMessage?: string;
};

export default function QuestionHoverCard({
  contentMessage,
  children,
}: { children?: React.ReactNode } & Props) {
  return (
    <HoverCard.Root openDelay={300}>
      <HoverCard.Trigger className={styles.trigger}>
        {/* ここ細かく最適化してるから、slotでいれるべきかもしれない */}
        <QuestionMarkCircledIcon className={styles.icon} />
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content className={styles.content}>
          <div>{contentMessage}</div>
          <Slot>{children}</Slot>
          <HoverCard.Arrow className={styles.arrow} />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
