import { isValidEmail } from "utils/strings/email";
import { PaymentMethodType } from "./StripeForm";

const emailRequiredPaymentMethods: PaymentMethodType[] = ["bank_transfer"];

export const isEmailRequired = (paymentMethodType: PaymentMethodType) => {
  return emailRequiredPaymentMethods.includes(paymentMethodType);
};

export const isValidEmailForPayment = (
  paymentMethod: PaymentMethodType,
  email: string
): boolean => {
  if (isEmailRequired(paymentMethod)) {
    return isValidEmail(email);
  }

  if (email === "") {
    return true;
  }
  return isValidEmail(email);
};
