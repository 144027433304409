import {
  CouponMasterForListFragment,
  CouponMasterNameFragment,
} from "gql/__generated__/graphql";
import styles from "./CouponMasterSelect.module.scss";
import Select from "react-select";
import { EditingRow } from "../../HomePage.logic";
import { SelectOption } from "utils/select";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";

type Props = {
  defaultValue?: CouponMasterNameFragment;
  editingRow?: EditingRow;
  availableCouponMasters: readonly CouponMasterForListFragment[];
  isEditablePaymentStatus: boolean;
  updateRow: (couponMasterId?: string) => void;
  onClickSelectButton: () => void;
};

const nullOption = {
  value: "null",
  label: "設定しない",
};

export default function CouponMasterSelect({
  availableCouponMasters,
  defaultValue,
  editingRow,
  isEditablePaymentStatus,
  updateRow,
  onClickSelectButton,
}: Props) {
  const { isMobile } = useMediaQueryContext();

  if (editingRow) {
    const selected = availableCouponMasters.find(
      (couponMaster) => couponMaster.id === editingRow.couponMasterId
    );
    const selectedOption: SelectOption = selected
      ? { value: selected.id, label: selected.name }
      : nullOption;

    let options: SelectOption[] = availableCouponMasters.map((couponMaster) => {
      return {
        value: couponMaster.id,
        label: couponMaster.name,
      };
    });
    options = options.concat(nullOption);

    const onChange = (value: string | undefined) => {
      if (!value || value === nullOption.value) {
        updateRow(undefined);
        return;
      }
      updateRow(value);
      return;
    };

    if (isMobile) {
      return (
        <select
          value={selectedOption.value}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    return (
      <Select
        className={styles.select}
        classNamePrefix="rs"
        value={selectedOption}
        onChange={(option) => onChange(option?.value)}
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    );
  }

  if (defaultValue) {
    return <span className={styles.coupon_enabled}>{defaultValue.name}</span>;
  }

  if (isEditablePaymentStatus) {
    return (
      <span className={styles.selectable} onClick={() => onClickSelectButton()}>
        選択
      </span>
    );
  }

  return <span className={styles.not_selected}>設定なし</span>;
}
