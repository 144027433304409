import { makeVar } from "@apollo/client";
import { useReactiveVarHooks } from ".";
import { useCallback } from "react";

export type ClaimRuleText = {
  defaultText?: string;
  isEditing: boolean;
  isChanged: boolean;
  text: string;
  charCount: number;
};

const initialClaimRuleText: ClaimRuleText = {
  defaultText: undefined,
  isEditing: false,
  isChanged: false,
  text: "",
  charCount: 0,
};

const claimRuleTextVar = makeVar<ClaimRuleText>(initialClaimRuleText);

// ページを変更しても編集中の値がなくならないようにしたいので、global stateとして定義
export const useClaimRuleText = () => {
  const [value, setValue] = useReactiveVarHooks(claimRuleTextVar);

  const setDefaultTextByQuery = useCallback(
    (defaultText: string) => {
      if (
        value.defaultText === undefined || // 初期ロード時
        value.defaultText !== defaultText // upsert mutationの結果が返ってきた時
      ) {
        setValue({
          ...initialClaimRuleText,
          defaultText,
          text: defaultText,
          charCount: defaultText.length,
        });
      }
    },
    [value, setValue]
  );

  const onClickStartEdit = useCallback(() => {
    setValue({
      ...value,
      isEditing: true,
    });
  }, [value, setValue]);

  const onClickCancelEdit = useCallback(() => {
    setValue({
      ...initialClaimRuleText,
      defaultText: value.defaultText,
      text: value.defaultText ?? "",
      charCount: value.defaultText?.length ?? 0,
    });
  }, [value, setValue]);

  const onChangeText = useCallback(
    (newText: string) => {
      setValue({
        ...value,
        isChanged: newText !== value.defaultText,
        text: newText,
        charCount: newText.length,
      });
    },
    [value, setValue]
  );

  return {
    value,
    setDefaultTextByQuery,
    onClickStartEdit,
    onClickCancelEdit,
    onChangeText,
  };
};
