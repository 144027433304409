import { ChangeEvent, InputHTMLAttributes } from "react";
import styles from "./LabeledForm.module.scss";

type Props = {
  labelText: string;
  input: {
    name: string;
    type: "text" | "password" | "number";
    value?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
  errorMessage: string;
};

export const LabeledForm = (props: Props) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{props.labelText}</label>
      <input
        name={props.input.name}
        type={props.input.type}
        value={props.input.value}
        onChange={props.input.onChange}
        className={styles.input}
      />
      {props.errorMessage && (
        <p className={styles.warning}>{props.errorMessage}</p>
      )}
    </div>
  );
};
