import { parse } from "date-fns";
import { isValidDate } from "utils/date";

const maintenanceFromStr = process.env.REACT_APP_MAINTENANCE_FROM;
const maintenanceToStr = process.env.REACT_APP_MAINTENANCE_TO;

const maintenanceFromDate = maintenanceFromStr
  ? parse(maintenanceFromStr, "yyyy-MM-ddHH:mm", new Date())
  : undefined;
const maintenanceToDate = maintenanceToStr
  ? parse(maintenanceToStr, "yyyy-MM-ddHH:mm", new Date())
  : undefined;

export const maintenanceFrom = isValidDate(maintenanceFromDate)
  ? maintenanceFromDate
  : undefined;

export const maintenanceTo = isValidDate(maintenanceToDate)
  ? maintenanceToDate
  : undefined;

console.log(
  maintenanceFromStr,
  maintenanceToStr,
  maintenanceFrom,
  maintenanceTo
);

export const isInMaintenance = maintenanceFrom || maintenanceTo;
