import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getCurrentUser, signIn, confirmSignIn } from "@aws-amplify/auth";
import styles from "./Login.module.scss";
import { LabeledForm } from "components/ui/LabeledForm/LabeledForm";
import { PasswordRequirements } from "components/ui/PasswordRequirements";
import { getPasswordError } from "domain/login";
import { isValidEmail } from "utils/strings/email";
import { useLocation, useNavigate } from "react-router-dom";
import { urlSafeDecode } from "utils/url/url";
import { useLoading } from "components/provider/loading/useLoading";

// loginはprovider/authはいかに置いた方が綺麗そう
function Login() {
  const { startLoading } = useLoading();
  const search = useLocation().search;
  const navigate = useNavigate();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [isFilledByQuery, setIsFilledByQuery] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isChange, setIsChange] = useState(false);
  document.title = "RefundHub | ログイン";

  useEffect(() => {
    async function checkUser() {
      try {
        const emailByQuery = query.get("email");
        if (emailByQuery) {
          setEmail(urlSafeDecode(emailByQuery));
        }

        const initPassword = query.get("init_password");
        if (initPassword) {
          setPassword(urlSafeDecode(initPassword));
        }

        if (emailByQuery && initPassword) {
          setIsFilledByQuery(true);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
    checkUser();
  }, [query]);

  // cognito login処理
  const cognitoSignIn = useCallback(
    async (event?) => {
      if (event) {
        event.preventDefault();
      }
      if (getPasswordError(password)) {
        // error message exists
        return;
      }

      console.log("trying to sign in");
      const stopLoading = startLoading();
      await signIn({
        username: email,
        password,
        options: {
          // optional
          // autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        },
      })
        .then((user) => {
          console.log("signIn then block started");
          if (
            user.nextStep.signInStep ===
            "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
          ) {
            setPassword("");
            setIsChange(true);
          } else {
            // ここくる？？
            window.location.reload();
          }
        })
        .catch((error) => {
          showLoginErrorByException(error);
          console.log(error);
        })
        .finally(stopLoading);
    },
    [email, password, startLoading]
  );

  useEffect(() => {
    if (isFilledByQuery) {
      (async () => {
        await cognitoSignIn();
        setIsFilledByQuery(false); // we want to call cognitoSignIn only once when isFilledByQuery is true
      })();
    }
  }, [isFilledByQuery, cognitoSignIn]);

  // パスワードの再設定
  async function cognitoConfirmSignIn(event) {
    event.preventDefault();
    if (getPasswordError(password)) {
      // error message exists
      return;
    }

    const stopLoading = startLoading();
    try {
      const user = await confirmSignIn({
        challengeResponse: password,
      });

      if (user.isSignedIn === true && user.nextStep.signInStep === "DONE") {
        // @ts-ignore
        window.location = window.location.href.split("?")[0]; // reload without query params
      }
    } catch (error) {
      showLoginErrorByException(error);
      console.log(error);
    } finally {
      stopLoading();
    }
  }

  const handleEmailChange = (e) => {
    e.preventDefault();
    const newEmail = e.target.value;
    setEmail(newEmail);
    setLoginError("");
    if (!isValidEmail(newEmail)) {
      setEmailError("正しいメールアドレスを入力してください。");
    } else {
      setEmailError("");
    }
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    const newPassword = e.target.value;
    const error = getPasswordError(newPassword);
    setPasswordError(error);
    setPassword(newPassword);
    setLoginError("");
  };

  const showLoginErrorByException = (exception) => {
    if (String(exception).startsWith("NotAuthorizedException")) {
      setLoginError("メールアドレスとパスワードが正しくありません。");
    } else {
      setLoginError(exception);
    }
  };

  return (
    <>
      {isChange ? (
        <>
          <form onSubmit={cognitoConfirmSignIn} className={styles.login_form}>
            <div className={styles.text}>
              <h1>パスワード変更（初回）</h1>
              <PasswordRequirements />
            </div>
            <LabeledForm
              labelText="新しいパスワード"
              input={{
                name: "password",
                type: showPassword ? "text" : "password",
                value: password,
                onChange: handlePasswordChange,
              }}
              errorMessage={passwordError}
            />
            <div className={styles.checkbox_container}>
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              <label className={styles.login_label}>パスワードを表示</label>
            </div>
            <div>
              <a
                href="/"
                onClick={() => {
                  navigate("/");
                  setIsChange(false);
                }}
              >
                戻る
              </a>
            </div>
            <button type="submit" className="common-button">
              変更
            </button>
          </form>
        </>
      ) : (
        <>
          <form onSubmit={cognitoSignIn} className={styles.login_form}>
            <div className={styles.text}>
              <h1>ログイン</h1>
              <p>登録済みのメールアドレスを入力してください。</p>
            </div>
            <LabeledForm
              labelText="メールアドレス"
              input={{
                name: "email",
                value: email,
                type: "text",
                onChange: handleEmailChange,
              }}
              errorMessage={emailError}
            />
            <LabeledForm
              labelText="パスワード"
              input={{
                name: "password",
                type: showPassword ? "text" : "password",
                value: password,
                onChange: handlePasswordChange,
              }}
              errorMessage={passwordError}
            />
            <div className={styles.checkbox_container}>
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={showPassword}
                onChange={(e) => setShowPassword(!showPassword)}
              />
              <label className={styles.login_label}>パスワードを表示</label>
            </div>
            <div>
              <a href="/forgot_password">パスワードを忘れた場合はこちら</a>
            </div>
            <button type="submit" className="common-button">
              ログイン
            </button>
            {loginError && <p>{loginError}</p>}
          </form>
        </>
      )}
    </>
  );
}

export default Login;
