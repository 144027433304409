export const getPasswordError = (password) => {
  if (password.length < 8) {
    return "パスワードは8文字以上である必要があります。";
  }
  if (!/\d/.test(password)) {
    return "パスワードには少なくとも1つの数字が必要です。";
  }
  if (!/[A-Z]/.test(password)) {
    return "パスワードには少なくとも1つの大文字が必要です。";
  }
  if (!/[a-z]/.test(password)) {
    return "パスワードには少なくとも1つの小文字が必要です。";
  }
  return "";
};
