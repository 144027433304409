import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { requiredMessage } from "../errorMessage";
import { Role } from "gql/__generated__/graphql";

export type SalonUserForm = yup.Asserts<typeof salonUserScheme>;

export const salonUserScheme = yup.object({
  email: yup
    .string()
    .required(requiredMessage("ユーザーのメールアドレス"))
    .email("メールアドレスの形式が正しくありません"),
  role: yup
    .mixed<Role>()
    .oneOf(["ADMIN", "MEMBER"])
    .required(requiredMessage("役割")),
});

export const salonUserResolver = yupResolver(salonUserScheme);
