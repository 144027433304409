import { Tab } from "components/features/home/HomePage.logic";
import {
  CancelForListFragment,
  CancelPaymentStatus,
  CancelType,
  CouponMasterNameFragment,
} from "gql/__generated__/graphql";

export const showEditButton = (paymentStatus: CancelPaymentStatus): boolean => {
  return paymentStatus === "INVOICE_NOT_SENT";
};

const validCancelTypesToSendInvoice: CancelType[] = [
  "NO_CONFIRMATION",
  "ON_THE_DAY",
  "PREVIOUS_DAY",
  "FROM_PREVIOUS_DAY_TO_TWO_DAYS_AGO",
  "SUBSCRIPTION_PAYMENT_FAILED",
  "SK_CLINIC_DENTAL_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_DENTAL_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO",
  "SK_CLINIC_DENTAL_ON_THE_DAY",
  "SK_CLINIC_DERMATOLOGY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_DERMATOLOGY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO",
  "SK_CLINIC_DERMATOLOGY_ON_THE_DAY",
  "SK_CLINIC_SURGERY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_SURGERY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO",
  "SK_CLINIC_SURGERY_ON_THE_DAY",
  "SK_CLINIC_SURGERY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_DERMATOLOGY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_DENTAL_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_SURGICAL_FOLLOW_UP_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO",
  "SK_CLINIC_MANUAL_INVOICE_PRICE_SETTING",
  "MANUAL_INVOICE_PRICE_SETTING",
];

export const isValidCancelTypeToSendInvoice = (
  cancelType: CancelType
): boolean => {
  return validCancelTypesToSendInvoice.includes(cancelType);
};

export const shouldUseDefaultCouponMaster = (
  currentTab: Tab,
  cancelRow: CancelForListFragment,
  enableDefaultCouponMasterForNoConfirmationCancel: boolean,
  defaultCouponMaster?: CouponMasterNameFragment
) => {
  if (currentTab !== "INVOICE_NOT_SENT" && currentTab !== "NOT_SUBJECT") {
    // 未請求, 非請求タブ以外
    return false;
  }

  if (
    cancelRow.cancelType === "NO_CONFIRMATION" &&
    !enableDefaultCouponMasterForNoConfirmationCancel
  ) {
    // 無断はクーポン付与しない設定
    return false;
  }

  // ユーザーが明示的に設定したことがない、かつデフォルトクーポンマスタが設定されているかどうか
  return (
    cancelRow.cancelCouponMasterSpecifiedBy === "UNSPECIFIED" &&
    defaultCouponMaster !== undefined
  );
};
