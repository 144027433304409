import React, { createContext, FC, useContext, useMemo } from "react";
import useMedia from "use-media";

type Props = {
  children: React.ReactNode;
};

type Context = {
  isMobile: boolean;
};

const MediaQueryContext = createContext<Context>({
  isMobile: false,
});

const mediaQueries = {
  mobile: "(max-width: 519px)",
};

export const MediaQueryProvider: FC<Props> = ({ children }: Props) => {
  const isMobile = useMedia(mediaQueries.mobile);
  const value = useMemo(() => ({ isMobile }), [isMobile]);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = (): Context =>
  useContext(MediaQueryContext);
