import styles from "./EmailFormLabel.module.scss";

type Props = {
  required: boolean;
  couponMasterExists: boolean;
};

export const EmailFormLabel = ({ required, couponMasterExists }: Props) => {
  return (
    <div className={styles.email_form_label}>
      {required ? (
        <span>
          <span>【</span>
          <span className={styles.required_prefix}>必須</span>
          <span>】</span>
        </span>
      ) : (
        <span>【任意】</span>
      )}
      {couponMasterExists ? (
        <span>特典受取メール</span>
      ) : (
        <span>支払い通知受取メール</span>
      )}
      {required && <span className={styles.required_flag}>*</span>}
    </div>
  );
};
