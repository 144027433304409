import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  SalonAllFieldFragmentDoc,
  SalonUserAllFieldFragmentDoc,
} from "gql/__generated__/graphql";
import { useCallback, useEffect, useState } from "react";
import { getFragmentData } from "gql/__generated__";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL + "/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  const session = await fetchAuthSession();
  const token = session.tokens?.accessToken.toString() ?? "";

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

type UseLoginReturn = {
  loading: boolean;
  loginData: LoginData | null;
};

type LoginData = {
  currentUserID: string;
  currentSalonID: number;
};

// this can be used even outside of ApolloProvider
export const useLogin = (startLoading?: () => () => void): UseLoginReturn => {
  const [loginData, setLoginData] = useState<LoginData | null>(null);
  const [loading, setLoading] = useState(true);

  const login = useCallback(async () => {
    const session = await fetchAuthSession();
    if (!session?.tokens) {
      console.log("fetch auth session failed");
      setLoading(false);
      return;
    }

    // ApolloProvider外で使用するため、useMutationは使用しない
    const apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
    const res = await apolloClient.mutate<
      LoginMutation,
      LoginMutationVariables
    >({ mutation: LoginDocument });
    apolloClient.stop();

    const data = res.data;
    if (!data) {
      setLoading(false);
      alert("エラーが発生しました。しばらく経ってから再度お試しください。");
      return;
    }
    const salon = getFragmentData(SalonAllFieldFragmentDoc, data.login.salon);
    const salonUser = getFragmentData(
      SalonUserAllFieldFragmentDoc,
      data.login.salonUser
    );

    // ログイン完了
    setLoading(false);
    setLoginData({ currentSalonID: salon.id, currentUserID: salonUser.id });
  }, []);

  // logoutしたらリロードされるので、useEffectはちゃんと起動する
  useEffect(() => {
    const asyncFunc = async () => {
      let stopLoading = () => {};
      if (startLoading) {
        stopLoading = startLoading();
      }
      try {
        await login();
      } catch (error) {
        console.log("login failed", error);
      } finally {
        stopLoading();
      }
    };
    asyncFunc();
  }, [startLoading, login]);

  return {
    loading,
    loginData,
  };
};

export default useLogin;
