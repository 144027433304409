import styles from "./EmitModalButton.module.scss";

type Props = {
  text: string;
  onClick: () => void;
  hide: boolean;
};

const EmitModalButton = ({ text, onClick, hide }: Props) => {
  return (
    <button
      className={styles.emit_modal_button}
      onClick={onClick}
      style={{ visibility: hide ? "hidden" : "visible" }}
    >
      {text}
    </button>
  );
};

export default EmitModalButton;
