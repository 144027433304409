import { useMutation } from "@apollo/client";
import {
  SalonAllFieldFragmentDoc,
  SwitchSalonDocument,
  SwitchSalonMutation,
  SwitchSalonMutationVariables,
} from "gql/__generated__/graphql";
import { getFragmentData } from "gql/__generated__";

const useSwitchSalon = () => {
  const [mutate, { client }] = useMutation<
    SwitchSalonMutation,
    SwitchSalonMutationVariables
  >(SwitchSalonDocument);

  const switchSalon = async (newSalonId: number) => {
    const res = await mutate({ variables: { salonId: newSalonId } });
    const salonId = getFragmentData(
      SalonAllFieldFragmentDoc,
      res.data?.switchSalon.salon
    )?.id;
    if (!salonId) {
      throw new Error("店舗の切り替えに失敗しました");
    }

    client.clearStore(); // clear all apollo cache data
    window.location.reload();
  };

  return switchSalon;
};

export default useSwitchSalon;
