import {
  SalonUserAllFieldFragment,
  UpdateSalonUserRoleInput,
} from "gql/__generated__/graphql";
import ModalDialog from "components/ui/ModalDialog/ModalDialog";
import ModalHeader from "../../common/ModalHeader/ModalHeader";
import ModalButtons from "../../common/ModalButtons/ModalButtons";
import { useCallback, useEffect, useState } from "react";
import styles from "./UpdateSalonUserRoleModal.module.scss";
import RoleRadioGroup from "../RoleRadioGroup/RoleRadioGroup";

type Props = {
  updateSalonUserRole: (input: UpdateSalonUserRoleInput) => Promise<void>;
};

type UseUpdateSalonUserRoleModalReturn = {
  render: () => JSX.Element;
  openModal: (salonUser: SalonUserAllFieldFragment) => void;
};

const useUpdateSalonUserRoleModal = ({
  updateSalonUserRole,
}: Props): UseUpdateSalonUserRoleModalReturn => {
  // states
  const [isOpen, setIsOpen] = useState(false);
  const [salonUser, setSalonUser] = useState<
    SalonUserAllFieldFragment | undefined
  >(undefined);
  const [role, setRole] = useState<"MEMBER" | "ADMIN">("MEMBER");
  useEffect(() => {
    if (!isOpen) {
      // reset form
      setSalonUser(undefined);
      setRole("MEMBER");
    }
  }, [isOpen]);

  // functions
  const onModalOpenChange = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onClickCancel = () => {
    setIsOpen(false);
  };

  const onOpenModal = useCallback((salonUser: SalonUserAllFieldFragment) => {
    setIsOpen(true);
    setSalonUser(salonUser);
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault(); // it's necessary because handleSubmit isn't used
      if (!salonUser) {
        return;
      }
      if (!window.confirm("役割を編集しますか？")) {
        return;
      }
      await updateSalonUserRole({
        role,
        userId: salonUser?.id ?? "",
      }).then(() => {
        setIsOpen(false);
        alert("役割を編集しました。");
      });
    },
    [updateSalonUserRole, salonUser, role]
  );

  const render = useCallback(() => {
    return (
      <ModalDialog isOpen={isOpen} onOpenChange={onModalOpenChange}>
        <div className={styles.modal_content}>
          <ModalHeader
            title="役割の編集"
            description="店舗に所属しているユーザーの役割を編集することができます。"
          />

          <form className={styles.form} onSubmit={onSubmit}>
            <RoleRadioGroup
              value={role}
              onValueChange={(value) => setRole(value)}
            />

            <div style={{ marginBottom: "20px" }}></div>

            <ModalButtons
              okText="保存"
              cancelText="キャンセル"
              onClickCancel={onClickCancel}
            />
          </form>
        </div>
      </ModalDialog>
    );
  }, [isOpen, onModalOpenChange, role, onSubmit]);

  return {
    render,
    openModal: onOpenModal,
  };
};

export default useUpdateSalonUserRoleModal;
