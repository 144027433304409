import { useQuery } from "@apollo/client";
import { getFragmentData } from "gql/__generated__";
import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  SalonAllFieldFragmentDoc,
  UserPermission,
} from "gql/__generated__/graphql";

type UseUserPermissionReturn = {
  refetch: () => void;
  userPermission?: UserPermission;
};

export const useUserPermission = (): UseUserPermissionReturn => {
  const { data, refetch } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    {
      fetchPolicy: "cache-first",
    }
  );
  const salon = getFragmentData(SalonAllFieldFragmentDoc, data?.me.salon);
  return {
    refetch,
    userPermission: salon?.userPermission,
  };
};
