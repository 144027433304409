import { CouponMasterForListFragment } from "gql/__generated__/graphql";
import { toStringWithCommas } from "utils/strings/number";

export const discountValue = (
  couponMaster: CouponMasterForListFragment
): string => {
  if (!!couponMaster.discountAmount) {
    return "¥" + toStringWithCommas(couponMaster.discountAmount);
  } else if (!!couponMaster.discountPercent) {
    return couponMaster.discountPercent.toString() + "%";
  }
  throw new Error("unexpectedly discountAmount and discountPercent is null");
};

export const invoiceCountExplainMessage = "特典をつけて請求したキャンセルの数";
export const issuedCountExplainMessage =
  "キャンセル料支払いが完了し、発行されたクーポンの数";
export const usedCountExplainMessage = "お客様に使用されたクーポンの数";
export const defaultCouponMasterExplainMessage =
  "キャンセルに対してデフォルトで選択されるクーポン";
