import SimpleInputLabel from "components/ui/SimpleInputLabel/SimpleInputLabel";
import styles from "./RoleRadioGroup.module.scss";

type Props = {
  value: "ADMIN" | "MEMBER";
  onValueChange: (value: "ADMIN" | "MEMBER") => void;
};

const RoleRadioGroup = ({ value, onValueChange }: Props) => {
  return (
    <div>
      <SimpleInputLabel title={"役割"} optional={false} />
      <div
        className={styles.radio_container}
        onClick={() => onValueChange("MEMBER")}
      >
        <input type="radio" value="MEMBER" checked={value === "MEMBER"} />
        <div className={styles.texts}>
          <label style={{ color: value === "MEMBER" ? "#ff8f4f" : "" }}>
            メンバー
          </label>
          <p>請求に関する操作のみ可能です。情報は全て閲覧できます。</p>
        </div>
      </div>
      <div
        className={styles.radio_container}
        onClick={() => onValueChange("ADMIN")}
      >
        <input type="radio" value="ADMIN" checked={value === "ADMIN"} />
        <div className={styles.texts}>
          <label style={{ color: value === "ADMIN" ? "#ff8f4f" : "" }}>
            管理者
          </label>
          <p>ほぼ全ての操作や情報の閲覧が可能です。</p>
        </div>
      </div>
    </div>
  );
};

export default RoleRadioGroup;
