import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeElements,
  StripeError,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { useState } from "react";
import apiService from "components/provider/rest";
import "../../common/index.css";
import styles from "./Payment.module.scss";
import { isValidEmailForPayment } from "./logic";

export type PaymentMethodType =
  | "card"
  | "bank_transfer"
  | "apple_pay"
  | "google_pay";

type Props = {
  amount: number;
  paymentID: string;
  clientSecret: string;
  email: string;
  paymentMethod: PaymentMethodType;
  onChangePaymentMethod: (paymentMethodType: PaymentMethodType) => void;
};

export default function StripeForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const onChange = (event: StripePaymentElementChangeEvent) => {
    console.log(event.value.type);
    if (event.value.type === "card") {
      props.onChangePaymentMethod("card");
    } else if (event.value.type === "customer_balance") {
      props.onChangePaymentMethod("bank_transfer");
    } else if (event.value.type === "apple_pay") {
      props.onChangePaymentMethod("apple_pay");
    } else if (event.value.type === "google_pay") {
      props.onChangePaymentMethod("google_pay");
    } else {
      console.log("unknown event type", event.value.type);
    }
  };

  const confirmPayment = async (
    stripe: Stripe,
    elements: StripeElements
  ): Promise<StripeError | undefined> => {
    let return_url =
      process.env.REACT_APP_WEB_URL + `/payment/complete/${props.paymentID}`;
    if (props.paymentMethod === "bank_transfer") {
      // 振込先がかかれたモーダルを閉じた後、complete画面にredirectさせない
      return_url =
        process.env.REACT_APP_WEB_URL +
        `/payment/${props.paymentID}?email=${props.email}`;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url,
        payment_method_data: {
          billing_details: {
            email: props.email,
            address: {
              country: "JP",
            },
          },
        },
      },
    });
    return result.error ? result.error : undefined;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !stripe ||
      !elements ||
      !isValidEmailForPayment(props.paymentMethod, props.email)
    ) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    if (props.email !== "") {
      // TODO: mutate
      await apiService.put("/public/payment/customer/" + props.paymentID, {
        email: props.email,
      });
    }

    try {
      const error = await confirmPayment(stripe, elements);
      if (!error) {
        return;
      }
      alert(
        getErrorMessage(error) +
          "カード情報を確認するか、別のクレジットカードをお試しください。"
      );
      console.log("confirmPayment error", error);
    } catch (err) {
      console.error("Error in stripe.confirmPayment: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  const errorMessages: {
    [errorMessages: string]: string | { [errorDeclineCode: string]: string };
  } = {
    card_declined: {
      insufficient_funds: "カードの残高が不足しています。",
      card_velocity_exceeded: "利用頻度の上限を超過しています。",
      generic_decline: "カードが拒否されました。",
    },
    expired_card: "カードの有効期限が間違っているか、期限が過ぎています。",
    incorrect_cvc: "セキュリティーコードの誤りがあります。",
    incorrect_number: "カードの番号に誤りがあります。",
  };

  const getErrorMessage = (error: StripeError): string => {
    const message = errorMessages[error.code ?? ""];
    if (typeof message === "string") {
      // expired_card, incorrect_number, incorrect_cvc の場合
      return message;
    } else if (message && error.decline_code && message[error.decline_code]) {
      // card_declined の場合、更に下層のdecline_codeを見る
      return message[error.decline_code];
    }
    return "エラーが発生しました。";
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "auto" as const,
    terms: {
      usBankAccount: "never" as const,
    },
    fields: {
      billingDetails: {
        email: "never" as const,
        address: {
          country: "never" as const,
        },
      },
    },
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement
          id="payment-element"
          options={paymentElementOptions}
          onChange={onChange}
        />
        <button
          type="submit"
          className={`common-button ${styles.payment_button}`}
          disabled={
            isLoading ||
            !stripe ||
            !elements ||
            !isValidEmailForPayment(props.paymentMethod, props.email)
          }
          id="submit"
        >
          {isLoading ? "処理中..." : `${props.amount}円支払う`}
        </button>
      </form>
    </>
  );
}
