import { CouponMasterForListFragment } from "gql/__generated__/graphql";
import styles from "./MobileCouponMastersTableView.module.scss";
import {
  discountValue,
  invoiceCountExplainMessage,
  issuedCountExplainMessage,
  usedCountExplainMessage,
} from "./CouponMastersTableView.common.logic";
import MobileTableRowAttribute from "./MobileTableRowAttribute/MobileTableRowAttribute";
import { toStringWithCommas } from "utils/strings/number";
import ActionDropdownMenu from "./ActionDropdownMenu/ActionDropdownMenu";
import { CheckCircledIcon } from "@radix-ui/react-icons";

type Props = {
  couponMasters?: readonly CouponMasterForListFragment[];
  isActive: boolean;
  showActionButton: boolean;
  isDefaultCouponMaster: (row: CouponMasterForListFragment) => boolean;
  onClickUpdateStatusButton: (row: CouponMasterForListFragment) => void;
  onClickDefaultCouponMasterButton: (row: CouponMasterForListFragment) => void;
};

const MobileCouponMastersTableView = ({
  couponMasters,
  isActive,
  showActionButton,
  isDefaultCouponMaster,
  onClickUpdateStatusButton,
  onClickDefaultCouponMasterButton,
}: Props) => {
  return (
    <div className={styles.table_view}>
      <div className={styles.count}>{couponMasters?.length ?? 0}件の結果</div>

      {couponMasters?.map((row) => (
        <div className={styles.item} key={row.id}>
          <MobileTableRowAttribute label="クーポン名" content={row.name} />
          <MobileTableRowAttribute label="割引対象" content={row.targetName} />
          <MobileTableRowAttribute
            label="割引額"
            content={discountValue(row)}
          />
          <MobileTableRowAttribute
            label="有効期限"
            content={row.expireDurationDay.toString() + "日"}
          />
          <MobileTableRowAttribute
            label="最低利用金額"
            content={toStringWithCommas(row.minAmount) + "円"}
          />
          <MobileTableRowAttribute
            label="請求済"
            content={row.cancelsAttachedToCouponMaster.relationSummary.invoiceCount.toString()}
            questionPopoverMessage={invoiceCountExplainMessage}
          />
          <MobileTableRowAttribute
            label="発行済"
            content={row.cancelsAttachedToCouponMaster.relationSummary.issuedCount.toString()}
            questionPopoverMessage={issuedCountExplainMessage}
          />
          <MobileTableRowAttribute
            label="使用済"
            content={row.cancelsAttachedToCouponMaster.relationSummary.usedCount.toString()}
            questionPopoverMessage={usedCountExplainMessage}
          />

          <div className={styles.action_button_container}>
            {isDefaultCouponMaster(row) && (
              <span className={styles.default_coupon_master}>
                <CheckCircledIcon className={styles.icon} />
                <label>デフォルトで付与</label>
              </span>
            )}
            <span
              style={{ visibility: showActionButton ? "visible" : "hidden" }}
            >
              <ActionDropdownMenu
                isActive={isActive}
                isDefaultCouponMaster={isDefaultCouponMaster(row)}
                onClickUpdateStatusButton={() => onClickUpdateStatusButton(row)}
                onClickDefaultCouponMasterButton={() =>
                  onClickDefaultCouponMasterButton(row)
                }
              />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileCouponMastersTableView;
