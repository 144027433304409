import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  SalonAllFieldFragmentDoc,
  UpdateSalonDocument,
  UpdateSalonMutation,
  UpdateSalonMutationVariables,
} from "gql/__generated__/graphql";
import ContentHeader from "../common/ContentHeader/ContentHeader";
import { useMutation, useQuery } from "@apollo/client";
import { getFragmentData } from "gql/__generated__";
import SimpleAttributeRow from "components/ui/SimpleAttributeRow/SimpleAttributeRow";
import SimpleInputLabel from "components/ui/SimpleInputLabel/SimpleInputLabel";
import { useForm } from "react-hook-form";
import { SalonDetailForm, salonDetailResolver } from "utils/forms/salonDetail";
import { useCallback, useEffect, useState } from "react";
import ModalDialog from "components/ui/ModalDialog/ModalDialog";
import ModalHeader from "../common/ModalHeader/ModalHeader";
import EmitModalButton from "../common/EmitModalButton/EmitModalButton";
import ModalButtons from "../common/ModalButtons/ModalButtons";
import styles from "./SalonDetailSetting.module.scss";

const salonNameTitle = "店舗名";
const salonDisplayNameTitle = "店舗名 (サイドバー)";
const salonAddressTitle = "店舗住所";
const salonNameExplainMessage = "請求書等に表示される店舗名";
const salonDisplayNameExplainMessages = () => {
  return (
    <div>
      <p>左側のサイドバーに表示される店舗名</p>
      <p>(請求書等に表示されることはありません)</p>
    </div>
  );
};
const salonAddressExplainMessage = "請求書などに表示される店舗の住所";

const SalonDetailSetting = () => {
  // salon data
  const { data } = useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, {
    fetchPolicy: "cache-first",
  });
  const salon = getFragmentData(SalonAllFieldFragmentDoc, data?.me.salon);
  const salonName = salon?.name ?? "";
  const salonDisplayName = salon?.displayName ?? salonName;
  const salonAddress = salon?.address ?? "";
  const userPermission = salon?.userPermission ?? undefined;

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onModalOpenChange = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, [setIsModalOpen]);

  // form
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: salonDetailResolver,
    defaultValues: {
      salonName,
      salonDisplayName,
      salonAddress,
    },
  });

  useEffect(() => {
    // 取得してきた値をセット
    setValue("salonName", salonName);
    setValue("salonDisplayName", salonDisplayName);
    setValue("salonAddress", salonAddress);
  }, [salonName, salonDisplayName, salonAddress, setValue]);

  useEffect(() => {
    if (!isModalOpen) {
      // formをclear
      setValue("salonName", salonName);
      setValue("salonDisplayName", salonDisplayName);
      setValue("salonAddress", salonAddress);
      clearErrors();
    }
  }, [
    isModalOpen,
    salonName,
    salonDisplayName,
    salonAddress,
    setValue,
    clearErrors,
  ]);

  // onSubmit
  const [mutate] = useMutation<
    UpdateSalonMutation,
    UpdateSalonMutationVariables
  >(UpdateSalonDocument);
  const onSubmit = async (data: SalonDetailForm) => {
    await mutate({
      variables: {
        input: {
          name: data.salonName,
          displayName: data.salonDisplayName,
          address: data.salonAddress,
        },
      },
    }).then(() => {
      alert("店舗情報を更新しました");
      setIsModalOpen(false);
    });
  };

  const onClickCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <ModalDialog isOpen={isModalOpen} onOpenChange={onModalOpenChange}>
        <div className={styles.modal_content}>
          <ModalHeader
            title="店舗の編集"
            description="店舗情報を編集します。"
          />
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <SimpleInputLabel
              title={salonNameTitle}
              optional={false}
              questionExplanation={salonNameExplainMessage}
            />
            <input
              type="text"
              placeholder={salonNameTitle}
              {...register("salonName")}
            />
            <div className={styles.warning}>{errors.salonName?.message}</div>

            <SimpleInputLabel
              title={salonDisplayNameTitle}
              optional={false}
              questionExplanationJSX={salonDisplayNameExplainMessages}
            />
            <input
              type="text"
              placeholder={salonDisplayNameTitle}
              {...register("salonDisplayName")}
            />
            <div className={styles.warning}>
              {errors.salonDisplayName?.message}
            </div>

            <SimpleInputLabel
              title={salonAddressTitle}
              optional={false}
              questionExplanation={salonAddressExplainMessage}
            />
            <input
              type="text"
              placeholder={salonAddressTitle}
              {...register("salonAddress")}
            />
            <div className={styles.warning}>{errors.salonAddress?.message}</div>

            <div style={{ marginBottom: "20px" }}></div>

            <ModalButtons
              okText="保存"
              cancelText="キャンセル"
              onClickCancel={onClickCancel}
            />
          </form>
        </div>
      </ModalDialog>

      <ContentHeader
        title="店舗情報"
        description="店舗情報です。店舗名や住所が変わった場合は、必ず編集をお願いします。"
        emittableModalButton={() => (
          <EmitModalButton
            text={"店舗の編集"}
            onClick={() => setIsModalOpen(true)}
            hide={!userPermission?.canUpdateSalon ?? true}
          />
        )}
      />

      <div>
        <SimpleAttributeRow
          label={salonNameTitle}
          questionPopoverMessage={salonNameExplainMessage}
          content={salonName}
        />
        <SimpleAttributeRow
          label={salonDisplayNameTitle}
          questionExplanationJSX={salonDisplayNameExplainMessages}
          content={salonDisplayName}
        />
        <SimpleAttributeRow
          label={salonAddressTitle}
          questionPopoverMessage={salonAddressExplainMessage}
          content={salonAddress}
        />
      </div>
    </div>
  );
};

export default SalonDetailSetting;
