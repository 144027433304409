import styles from "./OverlayLoading.module.scss";
import { ring2 } from "ldrs";

const OverlayLoading = () => {
  ring2.register();
  return (
    <div className={styles.loading_page}>
      <l-ring-2 color={"#d1d1d1"} />
    </div>
  );
};

export default OverlayLoading;
