const CognitoRegion = "ap-northeast-1";
const CognitoUserPool = String(process.env.REACT_APP_COGNITO_USER_POOL_ID);
const CognitoUserPoolClient = String(
  process.env.REACT_APP_COGNITO_USER_POOL_CLIENT
);

const CognitoDomainPrefix = "refundhub-v1";

const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: CognitoUserPoolClient,
      userPoolId: CognitoUserPool,
      // loginWith: { // Optional
      //   oauth: {
      //     domain: 'abcdefghij1234567890-29051e27.auth.us-east-1.amazoncognito.com',
      //     scopes: ['openid email phone profile aws.cognito.signin.user.admin '],
      //     redirectSignIn: ['http://localhost:3000/','https://example.com/'],
      //     redirectSignOut: ['http://localhost:3000/','https://example.com/'],
      //     responseType: 'code',
      //   },
      username: "false", // NOTE: maybe unnecessary
      //   email: 'false', // Optional
      //   phone: 'false', // Optional
    },
    region: CognitoRegion,
    userPoolId: CognitoUserPool,
    userPoolWebClientId: CognitoUserPoolClient,
    oauth: {
      domain: `${CognitoDomainPrefix}.auth.${CognitoRegion}.amazoncognito.com`,
      scope: ["openid"],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "token",
    },
  },
};

export default amplifyConfig;
