import { CouponMasterForListFragment } from "gql/__generated__/graphql";
import styles from "./CouponMastersTableView.module.scss";
import { toStringWithCommas } from "utils/strings/number";
import {
  defaultCouponMasterExplainMessage,
  discountValue,
  invoiceCountExplainMessage,
  issuedCountExplainMessage,
  usedCountExplainMessage,
} from "./CouponMastersTableView.common.logic";
import ActionDropdownMenu from "./ActionDropdownMenu/ActionDropdownMenu";
import { CheckCircledIcon, CheckIcon } from "@radix-ui/react-icons";
import { CheckboxField } from "@aws-amplify/ui-react";
import QuestionExplanationIcon from "components/ui/QuetionExplanationIcon/QuestionExplanationIcon";

type Props = {
  couponMasters?: readonly CouponMasterForListFragment[];
  isActive: boolean;
  showActionButton: boolean;
  isDefaultCouponMaster: (row: CouponMasterForListFragment) => boolean;
  onClickUpdateStatusButton: (row: CouponMasterForListFragment) => void;
  onClickDefaultCouponMasterButton: (row: CouponMasterForListFragment) => void;
};

type Header = {
  title: string;
  explainMessage?: string;
};

const headers: Header[] = [
  {
    title: "クーポン名",
  },
  {
    title: "割引対象",
  },
  {
    title: "割引額",
  },
  {
    title: "有効期限",
  },
  {
    title: "最低利用金額",
  },
  {
    title: "請求済",
    explainMessage: invoiceCountExplainMessage,
  },
  {
    title: "発行済",
    explainMessage: issuedCountExplainMessage,
  },
  {
    title: "使用済",
    explainMessage: usedCountExplainMessage,
  },
  {
    title: "デフォルトで付与",
    explainMessage: defaultCouponMasterExplainMessage,
  },
  {
    title: "アクション",
  },
];

const CouponMastersTableView = ({
  couponMasters,
  isActive,
  showActionButton,
  isDefaultCouponMaster,
  onClickUpdateStatusButton,
  onClickDefaultCouponMasterButton,
}: Props) => {
  return (
    <div className={styles.table_view}>
      <table className={styles.table}>
        <thead>
          <tr>
            {headers.map((header, _) => (
              <th key={header.title}>
                {header.title}
                {header.explainMessage && (
                  <span className={styles.question_hover_card}>
                    <QuestionExplanationIcon
                      contentMessage={header.explainMessage}
                    />
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {couponMasters?.map((row, i) => (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td>{row.targetName}</td>
              <td>{discountValue(row)}</td>
              <td>{row.expireDurationDay}日</td>
              <td>{toStringWithCommas(row.minAmount)}円</td>
              <td>
                {row.cancelsAttachedToCouponMaster.relationSummary.invoiceCount}
              </td>
              <td>
                {row.cancelsAttachedToCouponMaster.relationSummary.issuedCount}
              </td>
              <td>
                {row.cancelsAttachedToCouponMaster.relationSummary.usedCount}
              </td>
              <td>
                {isDefaultCouponMaster(row) && (
                  <CheckCircledIcon className={styles.icon} />
                )}
              </td>
              <td
                style={{ visibility: showActionButton ? "visible" : "hidden" }}
              >
                <ActionDropdownMenu
                  isActive={isActive}
                  isDefaultCouponMaster={isDefaultCouponMaster(row)}
                  onClickUpdateStatusButton={() =>
                    onClickUpdateStatusButton(row)
                  }
                  onClickDefaultCouponMasterButton={() =>
                    onClickDefaultCouponMasterButton(row)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CouponMastersTableView;
