import {
  CouponMasterForListFragment,
  CouponMasterNameFragment,
} from "gql/__generated__/graphql";

export const confirmDeactivateCouponMasterMessage = () => {
  let confirmMessage = "請求済のキャンセルにつけた特典は、取消されません。";
  confirmMessage +=
    "\n\nまた、特典が付けられた未請求キャンセルは、特典未選択になります。";
  confirmMessage += "\n\n選択肢から非表示にしますか?";
  return confirmMessage;
};

export const isDefaultCouponMaster = (
  row: CouponMasterForListFragment,
  defaultCouponMaster?: CouponMasterNameFragment
): boolean => {
  if (!defaultCouponMaster) {
    return false;
  }
  return row.id === defaultCouponMaster.id;
};

export const confirmActivateCouponMasterMessage = "選択肢に表示しますか?";
