import { useEffect, useState } from "react";
import useInvitation from "./useInvitation";
import { useNavigate, useParams } from "react-router-dom";
import { urlSafeEncode } from "utils/url/url";
import InvalidLink from "./InvalidLink/InvalidLink";
import PasswordSetByUser from "./PasswordSetByUser/PasswordSetByUser";

const SalonUserInvitationWithoutLoginData = () => {
  const secretValue = useParams().secret_value;
  const navigate = useNavigate();
  const { invitation, isValidLink } = useInvitation(secretValue ?? "");

  useEffect(() => {
    if (!invitation || !isValidLink) {
      return;
    }

    if (invitation.isPasswordSetByUser) {
      return;
    }
    navigate(
      "/?email=" +
        urlSafeEncode(invitation.email) +
        "&init_password=" +
        urlSafeEncode(invitation.initPassword)
    ); // FIXME: 本当はinvitation.salonIDのサロンにログインさせたい
  }, [invitation, isValidLink, navigate]);

  if (invitation?.isPasswordSetByUser) {
    return <PasswordSetByUser />;
  }

  if (isValidLink === false) {
    return <InvalidLink />;
  }

  return <div></div>;
};

export default SalonUserInvitationWithoutLoginData;
