import styles from "./ModalHeader.module.scss";

type Props = {
  title: string;
  description?: string;
};

const ModalHeader = ({ title, description }: Props) => {
  return (
    <div className={styles.content}>
      <h2>{title}</h2>
      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
};

export default ModalHeader;
