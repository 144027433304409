import { toStringWithCommas } from "utils/strings/number";
import styles from "./MobileSummaryItem.module.scss";

type Props = {
  title: string;
  amount: number;
};

export const MobileSummaryItem = (props: Props) => {
  return (
    <div className={styles.mobile_summary_item}>
      <p className={styles.title}>{props.title}</p>
      <p className={styles.amount_container}>
        <span className={styles.yen}>¥</span>
        <span className={styles.amount}>
          {toStringWithCommas(props.amount)}
        </span>
      </p>
    </div>
  );
};

export default MobileSummaryItem;
