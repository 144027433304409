import { useApolloClient } from "@apollo/client";
import { signOut } from "@aws-amplify/auth";

// inside ApolloProvider
const useLogout = () => {
  const apolloClient = useApolloClient();

  const logout = async () => {
    try {
      await apolloClient.clearStore(); // clear all apollo cache data
      await signOut();
    } catch (e) {
      console.error(e);
      alert("エラーが発生しました。リロードして再度お試しください。");
    }
  };

  return logout;
};

export default useLogout;
