import { useNavigate, useParams } from "react-router-dom";
import useInvitation from "./useInvitation";
import { useEffect } from "react";
import useSwitchSalon from "components/provider/auth/useSwitchSalon";
import useLogout from "components/provider/auth/useLogout";
import InvalidLink from "./InvalidLink/InvalidLink";

type Props = {
  currentUserID: string;
  currentSalonID: number;
};

const SalonUserInvitationWithLoginData = ({
  currentUserID,
  currentSalonID,
}: Props) => {
  const secretValue = useParams().secret_value;
  const navigate = useNavigate();
  const switchSalon = useSwitchSalon();
  const logout = useLogout();
  const { invitation, isValidLink } = useInvitation(secretValue ?? "");

  useEffect(() => {
    if (!invitation || !isValidLink) {
      return;
    }

    if (currentUserID === invitation.userID) {
      if (currentSalonID === invitation.salonID) {
        navigate("/");
      } else {
        switchSalon(invitation.salonID);
      }
    } else {
      console.log("logout");
      logout().then(() => window.location.reload());
    }
  }, [
    invitation,
    currentSalonID,
    currentUserID,
    switchSalon,
    logout,
    isValidLink,
    navigate,
  ]);

  if (isValidLink === false) {
    return <InvalidLink />;
  }

  return <div></div>;
};

export default SalonUserInvitationWithLoginData;
