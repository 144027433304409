export const moreThanMessage = (num: number) => {
  return num + "以上の数字を入力してください";
};

export const lessThanMessage = (num: number) => {
  return num + "以下の数字を入力してください";
};

export const requiredMessage = (name: string) => {
  return name + "は必須です";
};
