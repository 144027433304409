import * as Dialog from "@radix-ui/react-dialog";
import styles from "./ModalDialog.module.scss";

type Props = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
};

// providerとhookで提供するのもアリ
const ModalDialog = ({ isOpen, onOpenChange, children }: Props) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content className={styles.content}>{children}</Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ModalDialog;
