export const isValidDate = (date?: Date): boolean => {
  return !isNaN(date?.getTime() ?? NaN);
};

export const formatDateUntilDay = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}/${month}/${day}`;
};

export const convertGraphQLDateToDate = (dateStr: string): Date => {
  return new Date(dateStr);
};

export const convertDateToHumanReadableDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};
