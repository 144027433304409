export class HttpError extends Error {
  statusCode: number;
  statusText: string;

  constructor(statusCode: number, statusText: string) {
    super(statusText);
    this.statusCode = statusCode;
    this.statusText = statusText;
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

// FIXME: なぜか404とかでもTypeErrorがかえり、status codeをみれない
const apiService = {
  sendRequest: async (
    method,
    endpoint,
    params,
    startLoading?: () => () => void // this arg can be deleted if you make a provider for managing rest requests
  ) => {
    let stopLoading = () => {};
    if (startLoading) {
      stopLoading = startLoading();
    }

    try {
      let body: BodyInit | null | undefined = undefined;
      if (params) {
        body = JSON.stringify(params);
      }
      const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });
      // network errorの場合はここに来る前にthrowされるらしい
      // https://zenn.dev/junki555/articles/4ab67fc78ce64c
      if (!res.ok) {
        throw new HttpError(res.status, res.statusText);
      }
      const resJson = await res.json();
      // FIXME: bodyじゃなくてrootかえすようにしたい...
      return JSON.parse(resJson.body);
    } catch (error) {
      console.log("error", error);
      throw error;
    } finally {
      stopLoading();
    }
  },

  get: async (endpoint: string, startLoading?: () => () => void) => {
    return apiService.sendRequest("GET", endpoint, undefined, startLoading);
  },

  post: async (endpoint, params, startLoading?: () => () => void) => {
    return apiService.sendRequest("POST", endpoint, params, startLoading);
  },

  put: async (endpoint, params, startLoading?: () => () => void) => {
    return apiService.sendRequest("PUT", endpoint, params, startLoading);
  },
};

export default apiService;
