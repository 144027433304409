import {
  SalonUserAllFieldFragment,
  UserPermission,
} from "gql/__generated__/graphql";
import {
  deleteButtonVisible,
  editRoleButtonVisible,
  getVisibility,
  reinviteButtonVisible,
  role_text,
  switchOwnerButtonVisible,
  tableHeaders,
} from "./SalonUserstableView.logic";
import { formatDateUntilDay } from "utils/date";
import styles from "./SalonUsersTableView.module.scss";

type Props = {
  mySalonUser: SalonUserAllFieldFragment;
  salonUsers: readonly SalonUserAllFieldFragment[];
  userPermission: UserPermission;
  openUpdateSalonUserRoleModal: (salonUser: SalonUserAllFieldFragment) => void;
  onClickReinviteSalonUserButton: (
    salonUser: SalonUserAllFieldFragment
  ) => void;
  onClickSwitchOwnerButton: (userID: string) => void;
  onClickDeleteSalonUserButton: (userID: string) => void;
};

const SalonUsersTableView = ({
  mySalonUser,
  salonUsers,
  userPermission,
  openUpdateSalonUserRoleModal,
  onClickReinviteSalonUserButton,
  onClickSwitchOwnerButton,
  onClickDeleteSalonUserButton,
}: Props) => {
  return (
    <div className={styles.table_view}>
      <table>
        <thead>
          <tr>
            {tableHeaders.map((header: string, index) => {
              return <th key={index}>{header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {salonUsers.map((row, _) => (
            <tr key={row.id}>
              <td>{row.email}</td>
              <td>{role_text(row.role)}</td>
              <td>{formatDateUntilDay(new Date(row.createTime))}</td>
              <td>
                {row.lastLoginTime
                  ? formatDateUntilDay(new Date(row.lastLoginTime))
                  : "招待メール送信済み"}
              </td>
              <td>
                <button
                  className={styles.action_button}
                  style={{
                    visibility: getVisibility(
                      editRoleButtonVisible(
                        row,
                        mySalonUser ?? null,
                        userPermission
                      )
                    ),
                  }}
                  onClick={() => openUpdateSalonUserRoleModal(row)}
                >
                  編集
                </button>
                {reinviteButtonVisible(row, userPermission) ? (
                  <button
                    className={styles.action_button}
                    style={{
                      visibility: getVisibility(
                        reinviteButtonVisible(row, userPermission)
                      ),
                    }}
                    onClick={() => onClickReinviteSalonUserButton(row)}
                  >
                    招待メール再送
                  </button>
                ) : (
                  <button
                    className={styles.action_button}
                    style={{
                      visibility: getVisibility(
                        switchOwnerButtonVisible(
                          row,
                          mySalonUser ?? null,
                          userPermission
                        )
                      ),
                    }}
                    onClick={() => onClickSwitchOwnerButton(row.id)}
                  >
                    オーナーの変更
                  </button>
                )}
                <button
                  className={styles.action_button}
                  style={{
                    visibility: getVisibility(
                      deleteButtonVisible(
                        row,
                        mySalonUser ?? null,
                        userPermission
                      )
                    ),
                  }}
                  onClick={() => onClickDeleteSalonUserButton(row.id)}
                >
                  削除
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalonUsersTableView;
