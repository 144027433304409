import QuestionExplanationIcon from "../QuetionExplanationIcon/QuestionExplanationIcon";
import styles from "./SimpleInputLabel.module.scss";

type Props = {
  title: string;
  optional: boolean;
  questionExplanation?: string;
  questionExplanationJSX?: () => JSX.Element;
  bold?: boolean;
};

const Label = ({
  optional,
  title,
  questionExplanation,
  questionExplanationJSX,
  bold,
}: Props) => {
  return (
    <div
      className={styles.label}
      style={{ fontWeight: bold ? "bold" : "normal" }}
    >
      {title}
      {optional && <span>（任意）</span>}
      {questionExplanation && (
        <QuestionExplanationIcon contentMessage={questionExplanation}>
          {questionExplanationJSX && questionExplanationJSX()}
        </QuestionExplanationIcon>
      )}
    </div>
  );
};

export default Label;
