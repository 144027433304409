import { useLoading } from "components/provider/loading/useLoading";
import apiService, { HttpError } from "components/provider/rest";
import { useEffect, useState } from "react";

export type Invitation = {
  salonID: number;
  userID: string;
  email: string;
  initPassword: string;
  isPasswordSetByUser: boolean;
};

const useInvitation = (secretValue: string) => {
  const { startLoading } = useLoading();
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [isValidLink, setIsValidLink] = useState<boolean | null>(null);

  useEffect(() => {
    const stopLoading = startLoading();
    apiService
      .get("/public/salon_user_invitation/" + secretValue, startLoading)
      .then((res) => {
        // FIXME: 404来た時に判別できない状態なので無理やりこうしてる。
        if (res.message === "NotFound") {
          setIsValidLink(false);
          return;
        }
        const invitation: Invitation = {
          salonID: res.salon_id,
          userID: res.user_id,
          email: res.email,
          initPassword: res.init_password,
          isPasswordSetByUser: res.is_password_set_by_user,
        };
        setInvitation(invitation);
        setIsValidLink(true);
      })
      // 本来はこうやってハンドリングするべき
      // .catch((error: HttpError) => {
      //   console.log(
      //     "fetching salon user invitation failed",
      //     error,
      //     error.statusCode
      //   );
      //   if (error.statusCode === 404) {
      //     setIsValidLink(false);
      //     return;
      //   }
      //   throw error;
      // })
      .catch((e) => {
        console.log("unexpected error while fetching salon user invitation", e);
        throw e;
      })
      .finally(() => stopLoading());
  }, [secretValue, startLoading]);

  return {
    invitation,
    isValidLink,
  };
};

export default useInvitation;
