import styles from "./ModalButtons.module.scss";

type Props = {
  okText: string;
  cancelText: string;
  onClickCancel: () => void;
};

const ModalButtons = ({ okText, cancelText, onClickCancel }: Props) => {
  return (
    <div className={styles.buttons}>
      <button onClick={onClickCancel}>{cancelText}</button>

      {/* 親の親のformに伝えるためsubmit */}
      <button type="submit" className={styles.ok}>
        {okText}
      </button>
    </div>
  );
};

export default ModalButtons;
