import {
  CancelType,
  CreateCancelCancelTypeInput,
} from "gql/__generated__/graphql";

export const defaultCancelTypeToCreateInNewCancelPage: CreateCancelCancelTypeInput =
  "ON_THE_DAY"; // 現状は全部の cancel policy template でON_THE_DAYが入るので、定数でOK

export const convertCancelTypeToCreateCancelCancelTypeInput = (
  cancelType: CancelType
): CreateCancelCancelTypeInput => {
  switch (cancelType) {
    case "NO_CONFIRMATION":
      return "NO_CONFIRMATION";
    case "ON_THE_DAY":
      return "ON_THE_DAY";
    case "PREVIOUS_DAY":
      return "PREVIOUS_DAY";
    case "FROM_PREVIOUS_DAY_TO_TWO_DAYS_AGO":
      return "FROM_PREVIOUS_DAY_TO_TWO_DAYS_AGO";
    case "SUBSCRIPTION_PAYMENT_FAILED":
      return "SUBSCRIPTION_PAYMENT_FAILED";
    case "SK_CLINIC_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_SURGERY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_SURGERY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_DERMATOLOGY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_DERMATOLOGY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_DENTAL_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_DENTAL_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_DENTAL_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_DENTAL_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_DENTAL_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO":
      return "SK_CLINIC_DENTAL_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO";
    case "SK_CLINIC_DENTAL_ON_THE_DAY":
      return "SK_CLINIC_DENTAL_ON_THE_DAY";
    case "SK_CLINIC_DERMATOLOGY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_DERMATOLOGY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_DERMATOLOGY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO":
      return "SK_CLINIC_DERMATOLOGY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO";
    case "SK_CLINIC_DERMATOLOGY_ON_THE_DAY":
      return "SK_CLINIC_DERMATOLOGY_ON_THE_DAY";
    case "SK_CLINIC_SURGERY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_SURGERY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_SURGERY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO":
      return "SK_CLINIC_SURGERY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO";
    case "SK_CLINIC_SURGERY_ON_THE_DAY":
      return "SK_CLINIC_SURGERY_ON_THE_DAY";
    case "SK_CLINIC_SURGICAL_FOLLOW_UP_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return "SK_CLINIC_SURGICAL_FOLLOW_UP_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case "SK_CLINIC_MANUAL_INVOICE_PRICE_SETTING":
      return "SK_CLINIC_MANUAL_INVOICE_PRICE_SETTING";
    case "MANUAL_INVOICE_PRICE_SETTING":
      return "MANUAL_INVOICE_PRICE_SETTING";
    default:
      throw new Error(`Invalid cancelType: ${cancelType}`);
  }
};

export const unspecifiedText = "不明";
export const noConfirmationText = "無断";
export const onTheDayText = "当日";
export const previousDayText = "前日";
export const moreThanTwoDaysAgoText = "2日以上前";
export const fromPreviousDayToTwoDaysAgoText = "前日から2日前";
export const moreThanThreeDaysAgoText = "3日以上前";
export const subscriptionPaymentFailedText = "サブスク決済失敗";
export const skClinicUnknownText = "科目不明";
export const skClinicSurgeryUnspecifiedText = "外科 キャンセル日不明";
export const skClinicSurgeryOnTheDayText = "外科 当日";
export const skClinicSurgeryFromPreviousDayToThreeDaysAgoText =
  "外科 前日から3日前";
export const skClinicSurgeryFromFourDaysAgoToSevenDaysAgoText =
  "外科 4日前から7日前";
export const skClinicSurgeryMoreThanEightDaysAgoText = "外科 8日以上前";
export const skClinicDermatologyUnspecifiedText = "皮膚科 キャンセル日不明";
export const skClinicDermatologyOnTheDayText = "皮膚科 当日";
export const skClinicDermatologyFromPreviousDayToThreeDaysAgoText =
  "皮膚科 前日から3日前";
export const skClinicDermatologyFromFourDaysAgoToSevenDaysAgoText =
  "皮膚科 4日前から7日前";
export const skClinicDermatologyMoreThanEightDaysAgoText = "皮膚科 8日以上前";
export const skClinicDentalUnspecifiedText = "歯科 キャンセル日不明";
export const skClinicDentalOnTheDayText = "歯科 当日";
export const skClinicDentalFromPreviousDayToThreeDaysAgoText =
  "歯科 前日から3日前";
export const skClinicDentalFromFourDaysAgoToSevenDaysAgoText =
  "歯科 4日前から7日前";
export const skClinicDentalMoreThanEightDaysAgoText = "歯科 8日以上前";
export const skClinicCounselingUnspecifiedText =
  "カウンセリング キャンセル日不明";
export const skClinicCounselingFromOnTheDayToSevenDaysAgoText =
  "カウンセリング 当日から7日前";
export const skClinicCounselingMoreThanEightDaysAgoText =
  "カウンセリング 8日以上前";
export const skClinicSurgeryCounselingUnspecifiedText =
  "外科カウンセリング キャンセル日不明";
export const skClinicSurgeryCounselingFromOnTheDayToSevenDaysAgoText =
  "外科カウンセリング 当日から7日前";
export const skClinicSurgeryCounselingMoreThanEightDaysAgoText =
  "外科カウンセリング 8日以上前";
export const skClinicDermatologyCounselingUnspecifiedText =
  "皮膚科カウンセリング キャンセル日不明";
export const skClinicDermatologyCounselingFromOnTheDayToSevenDaysAgoText =
  "皮膚科カウンセリング 当日から7日前";
export const skClinicDermatologyCounselingMoreThanEightDaysAgoText =
  "皮膚科カウンセリング 8日以上前";
export const skClinicDentalCounselingUnspecifiedText =
  "歯科カウンセリング キャンセル日不明";
export const skClinicDentalCounselingFromOnTheDayToSevenDaysAgoText =
  "歯科カウンセリング 当日から7日前";
export const skClinicDentalCounselingMoreThanEightDaysAgoText =
  "歯科カウンセリング 8日以上前";
export const skClinicSurgicalFollowUpUnspecifiedText =
  "外科 経過観察 キャンセル日不明";
export const skClinicSurgicalFollowUpFromOnTheDayToSevenDaysAgoText =
  "外科 経過観察 当日から7日前";
export const skClinicSurgicalFollowUpMoreThanEightDaysAgoText =
  "外科 経過観察 8日以上前";
export const skClinicManualInvoicePriceSettingText = "請求金額手動設定";
export const manualInvoicePriceSettingText = "請求金額 手動設定"; // skClinicManualInvoicePriceSettingText と無理やり差分つけてる😇

export const convertCancelTypeToString = (cancelType: CancelType): string => {
  switch (cancelType) {
    case "UNSPECIFIED":
      return unspecifiedText;
    case "NO_CONFIRMATION":
      return noConfirmationText;
    case "ON_THE_DAY":
      return onTheDayText;
    case "PREVIOUS_DAY":
      return previousDayText;
    case "SUBSCRIPTION_PAYMENT_FAILED":
      return subscriptionPaymentFailedText;
    case "MORE_THAN_TWO_DAYS_AGO":
      return moreThanTwoDaysAgoText;
    case "FROM_PREVIOUS_DAY_TO_TWO_DAYS_AGO":
      return fromPreviousDayToTwoDaysAgoText;
    case "MORE_THAN_THREE_DAYS_AGO":
      return moreThanThreeDaysAgoText;
    case "SK_CLINIC_UNKNOWN":
      return skClinicUnknownText;
    case "SK_CLINIC_SURGERY_UNSPECIFIED":
      return skClinicSurgeryUnspecifiedText;
    case "SK_CLINIC_SURGERY_ON_THE_DAY":
      return skClinicSurgeryOnTheDayText;
    case "SK_CLINIC_SURGERY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO":
      return skClinicSurgeryFromPreviousDayToThreeDaysAgoText;
    case "SK_CLINIC_SURGERY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO":
      return skClinicSurgeryFromFourDaysAgoToSevenDaysAgoText;
    case "SK_CLINIC_SURGERY_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicSurgeryMoreThanEightDaysAgoText;
    case "SK_CLINIC_DERMATOLOGY_UNSPECIFIED":
      return skClinicDermatologyUnspecifiedText;
    case "SK_CLINIC_DERMATOLOGY_ON_THE_DAY":
      return skClinicDermatologyOnTheDayText;
    case "SK_CLINIC_DERMATOLOGY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO":
      return skClinicDermatologyFromPreviousDayToThreeDaysAgoText;
    case "SK_CLINIC_DERMATOLOGY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO":
      return skClinicDermatologyFromFourDaysAgoToSevenDaysAgoText;
    case "SK_CLINIC_DERMATOLOGY_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicDermatologyMoreThanEightDaysAgoText;
    case "SK_CLINIC_DENTAL_UNSPECIFIED":
      return skClinicDentalUnspecifiedText;
    case "SK_CLINIC_DENTAL_ON_THE_DAY":
      return skClinicDentalOnTheDayText;
    case "SK_CLINIC_DENTAL_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO":
      return skClinicDentalFromPreviousDayToThreeDaysAgoText;
    case "SK_CLINIC_DENTAL_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO":
      return skClinicDentalFromFourDaysAgoToSevenDaysAgoText;
    case "SK_CLINIC_DENTAL_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicDentalMoreThanEightDaysAgoText;
    case "SK_CLINIC_COUNSELING_UNSPECIFIED":
      return skClinicCounselingUnspecifiedText;
    case "SK_CLINIC_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return skClinicCounselingFromOnTheDayToSevenDaysAgoText;
    case "SK_CLINIC_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicCounselingMoreThanEightDaysAgoText;
    case "SK_CLINIC_SURGERY_COUNSELING_UNSPECIFIED":
      return skClinicSurgeryCounselingUnspecifiedText;
    case "SK_CLINIC_SURGERY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return skClinicSurgeryCounselingFromOnTheDayToSevenDaysAgoText;
    case "SK_CLINIC_SURGERY_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicSurgeryCounselingMoreThanEightDaysAgoText;
    case "SK_CLINIC_DERMATOLOGY_COUNSELING_UNSPECIFIED":
      return skClinicDermatologyCounselingUnspecifiedText;
    case "SK_CLINIC_DERMATOLOGY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return skClinicDermatologyCounselingFromOnTheDayToSevenDaysAgoText;
    case "SK_CLINIC_DERMATOLOGY_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicDermatologyCounselingMoreThanEightDaysAgoText;
    case "SK_CLINIC_DENTAL_COUNSELING_UNSPECIFIED":
      return skClinicDentalCounselingUnspecifiedText;
    case "SK_CLINIC_DENTAL_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return skClinicDentalCounselingFromOnTheDayToSevenDaysAgoText;
    case "SK_CLINIC_DENTAL_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicDentalCounselingMoreThanEightDaysAgoText;
    case "SK_CLINIC_SURGICAL_FOLLOW_UP_UNSPECIFIED":
      return skClinicSurgicalFollowUpUnspecifiedText;
    case "SK_CLINIC_SURGICAL_FOLLOW_UP_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO":
      return skClinicSurgicalFollowUpFromOnTheDayToSevenDaysAgoText;
    case "SK_CLINIC_SURGICAL_FOLLOW_UP_MORE_THAN_EIGHT_DAYS_AGO":
      return skClinicSurgicalFollowUpMoreThanEightDaysAgoText;
    case "SK_CLINIC_MANUAL_INVOICE_PRICE_SETTING":
      return skClinicManualInvoicePriceSettingText;
    case "MANUAL_INVOICE_PRICE_SETTING":
      return manualInvoicePriceSettingText;
    default:
      return unspecifiedText;
  }
};

export const convertStringToCancelType = (cancelType: string): CancelType => {
  switch (cancelType) {
    case unspecifiedText:
      return "UNSPECIFIED";
    case noConfirmationText:
      return "NO_CONFIRMATION";
    case onTheDayText:
      return "ON_THE_DAY";
    case previousDayText:
      return "PREVIOUS_DAY";
    case subscriptionPaymentFailedText:
      return "SUBSCRIPTION_PAYMENT_FAILED";
    case moreThanTwoDaysAgoText:
      return "MORE_THAN_TWO_DAYS_AGO";
    case fromPreviousDayToTwoDaysAgoText:
      return "FROM_PREVIOUS_DAY_TO_TWO_DAYS_AGO";
    case moreThanThreeDaysAgoText:
      return "MORE_THAN_THREE_DAYS_AGO";
    case skClinicUnknownText:
      return "SK_CLINIC_UNKNOWN";
    case skClinicSurgeryUnspecifiedText:
      return "SK_CLINIC_SURGERY_UNSPECIFIED";
    case skClinicSurgeryOnTheDayText:
      return "SK_CLINIC_SURGERY_ON_THE_DAY";
    case skClinicSurgeryFromPreviousDayToThreeDaysAgoText:
      return "SK_CLINIC_SURGERY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO";
    case skClinicSurgeryFromFourDaysAgoToSevenDaysAgoText:
      return "SK_CLINIC_SURGERY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO";
    case skClinicSurgeryMoreThanEightDaysAgoText:
      return "SK_CLINIC_SURGERY_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicDermatologyUnspecifiedText:
      return "SK_CLINIC_DERMATOLOGY_UNSPECIFIED";
    case skClinicDermatologyOnTheDayText:
      return "SK_CLINIC_DERMATOLOGY_ON_THE_DAY";
    case skClinicDermatologyFromPreviousDayToThreeDaysAgoText:
      return "SK_CLINIC_DERMATOLOGY_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO";
    case skClinicDermatologyFromFourDaysAgoToSevenDaysAgoText:
      return "SK_CLINIC_DERMATOLOGY_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO";
    case skClinicDermatologyMoreThanEightDaysAgoText:
      return "SK_CLINIC_DERMATOLOGY_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicDentalUnspecifiedText:
      return "SK_CLINIC_DENTAL_UNSPECIFIED";
    case skClinicDentalOnTheDayText:
      return "SK_CLINIC_DENTAL_ON_THE_DAY";
    case skClinicDentalFromPreviousDayToThreeDaysAgoText:
      return "SK_CLINIC_DENTAL_FROM_PREVIOUS_DAY_TO_THREE_DAYS_AGO";
    case skClinicDentalFromFourDaysAgoToSevenDaysAgoText:
      return "SK_CLINIC_DENTAL_FROM_FOUR_DAYS_AGO_TO_SEVEN_DAYS_AGO";
    case skClinicDentalMoreThanEightDaysAgoText:
      return "SK_CLINIC_DENTAL_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicCounselingUnspecifiedText:
      return "SK_CLINIC_COUNSELING_UNSPECIFIED";
    case skClinicCounselingFromOnTheDayToSevenDaysAgoText:
      return "SK_CLINIC_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case skClinicCounselingMoreThanEightDaysAgoText:
      return "SK_CLINIC_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicSurgeryCounselingUnspecifiedText:
      return "SK_CLINIC_SURGERY_COUNSELING_UNSPECIFIED";
    case skClinicSurgeryCounselingFromOnTheDayToSevenDaysAgoText:
      return "SK_CLINIC_SURGERY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case skClinicSurgeryCounselingMoreThanEightDaysAgoText:
      return "SK_CLINIC_SURGERY_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicDermatologyCounselingUnspecifiedText:
      return "SK_CLINIC_DERMATOLOGY_COUNSELING_UNSPECIFIED";
    case skClinicDermatologyCounselingFromOnTheDayToSevenDaysAgoText:
      return "SK_CLINIC_DERMATOLOGY_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case skClinicDermatologyCounselingMoreThanEightDaysAgoText:
      return "SK_CLINIC_DERMATOLOGY_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicDentalCounselingUnspecifiedText:
      return "SK_CLINIC_DENTAL_COUNSELING_UNSPECIFIED";
    case skClinicDentalCounselingFromOnTheDayToSevenDaysAgoText:
      return "SK_CLINIC_DENTAL_COUNSELING_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case skClinicDentalCounselingMoreThanEightDaysAgoText:
      return "SK_CLINIC_DENTAL_COUNSELING_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicSurgicalFollowUpUnspecifiedText:
      return "SK_CLINIC_SURGICAL_FOLLOW_UP_UNSPECIFIED";
    case skClinicSurgicalFollowUpFromOnTheDayToSevenDaysAgoText:
      return "SK_CLINIC_SURGICAL_FOLLOW_UP_FROM_ON_THE_DAY_TO_SEVEN_DAYS_AGO";
    case skClinicSurgicalFollowUpMoreThanEightDaysAgoText:
      return "SK_CLINIC_SURGICAL_FOLLOW_UP_MORE_THAN_EIGHT_DAYS_AGO";
    case skClinicManualInvoicePriceSettingText:
      return "SK_CLINIC_MANUAL_INVOICE_PRICE_SETTING";
    case manualInvoicePriceSettingText:
      return "MANUAL_INVOICE_PRICE_SETTING";
    default:
      return "UNSPECIFIED";
  }
};
