import { useLoading } from "components/provider/loading/useLoading";
import apiService from "components/provider/rest";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ReservationPageRedirect = () => {
  const { startLoading } = useLoading();
  const cancelID = useParams().cancel_id;

  useEffect(() => {
    const f = async () => {
      await apiService
        .get(
          "/public/reservation_page_redirect_link/cancel/" + cancelID,
          startLoading
        )
        .then((res) => {
          window.location.href = res.link;
        })
        .catch((err) => {
          console.log(err);
          alert("エラーが発生しました。しばらく経ってから再度お試しください。");
        });
    };
    f();
  }, [cancelID, startLoading]);

  return <div>リダイレクト中...</div>;
};

export default ReservationPageRedirect;
