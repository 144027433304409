import {
  CancelType,
  CancelForListFragment,
  CancelPaymentStatus,
  CancelRereservationStatus,
} from "gql/__generated__/graphql";
import _ from "lodash";

export type EditingRow = {
  cancelType: CancelType;
  originPrice: string; // inputを空にもできるようにするためstring。空文字か数字しか入らないはずではある。現状先頭0入ってしまう。tableのorigin_priceはnumber型
  invoicePrice: number;
  phoneNumber: string; // 数字しか入力できないが、0始まりOK
  name: string;
  couponMasterId?: string;
};

export type EditingRereservationRow = {
  phoneNumber: string; // 数字しか入力できないが、0始まりOK
};

export type Tab = CancelPaymentStatus | CancelRereservationStatus;

export const confirmUpdatePaymentStatusButtonMessage = (
  cancels: readonly CancelForListFragment[],
  checkedCancelMap: Record<number, CancelForListFragment>,
  currentTab: "INVOICE_NOT_SENT" | "NOT_SUBJECT"
) => {
  let tabName = "非請求タブ";
  if (currentTab === "NOT_SUBJECT") {
    tabName = "未請求タブ";
  }

  let message =
    _.size(checkedCancelMap) +
    "件選択されています。\n" +
    tabName +
    "に移しますか?\n\n" +
    "来店予定日 請求先\n";

  cancels.forEach((cancel) => {
    if (cancel.id in checkedCancelMap) {
      message += cancel.dateVisit + " " + cancel.name + "\n";
    }
  });

  return message;
};
