import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CancelForm, cancelResolver } from "utils/forms/cancel";
import styles from "./NewCancelPage.module.scss";
import SimpleInputLabel from "components/ui/SimpleInputLabel/SimpleInputLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CancelType,
  CouponMasterForListFragmentDoc,
  CouponMasterNameFragmentDoc,
  CreateCancelCancelTypeInput,
  CreateCancelDocument,
  CreateCancelMutation,
  CreateCancelMutationVariables,
  CreateCancelPaymentStatusInput,
  GetCouponMastersDocument,
  GetCouponMastersQuery,
  GetCouponMastersQueryVariables,
  GetInvoicePriceDocument,
  GetInvoicePriceQuery,
  GetInvoicePriceQueryVariables,
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  SalonAllFieldFragmentDoc,
} from "gql/__generated__/graphql";
import { getFragmentData } from "gql/__generated__";
import _ from "lodash";
import {
  convertCancelTypeToCreateCancelCancelTypeInput,
  convertCancelTypeToString,
} from "domain/cancel";

const NewCancelPage = () => {
  const { data: meData } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    { fetchPolicy: "cache-first" }
  );
  const salon = getFragmentData(SalonAllFieldFragmentDoc, meData?.me.salon);
  const defaultSelectedCancelTypeForCreatingCancel =
    salon?.defaultSelectedCancelTypeForCreatingCancel;

  if (defaultSelectedCancelTypeForCreatingCancel) {
    return (
      <NewCancel
        defaultSelectedCancelTypeForCreatingCancel={
          defaultSelectedCancelTypeForCreatingCancel
        }
      />
    );
  }

  return <div>loading...</div>;
};

type Props = {
  defaultSelectedCancelTypeForCreatingCancel: CreateCancelCancelTypeInput;
};

const NewCancel = ({ defaultSelectedCancelTypeForCreatingCancel }: Props) => {
  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: cancelResolver,
    defaultValues: {
      visitCounts: 0,
      originPrice: 5000,
      name: "",
      // staff_name: "",
      phoneNumber: "",
    },
  });
  const [visitDate, setVisitDate] = useState<Date>(new Date());
  const [cancelType, _setCancelType] = useState<CreateCancelCancelTypeInput>(
    defaultSelectedCancelTypeForCreatingCancel
  );
  const [paymentStatus, setPaymentStatus] =
    useState<CreateCancelPaymentStatusInput>("CLAIMED");
  const originPriceRaw = watch("originPrice"); // 型嘘つく

  const setCancelType = useCallback(
    (cancelType: CancelType) => {
      _setCancelType(
        convertCancelTypeToCreateCancelCancelTypeInput(cancelType)
      );
    },
    [_setCancelType]
  );

  const { data: invoicePriceData } = useQuery<
    GetInvoicePriceQuery,
    GetInvoicePriceQueryVariables
  >(GetInvoicePriceDocument, {
    variables: {
      param: {
        originPrice: Number(originPriceRaw),
        cancelType,
      },
    },
    fetchPolicy: "no-cache",
  });
  const invoicePrice = invoicePriceData?.invoicePrice.invoicePrice;

  const { data: meData } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    { fetchPolicy: "cache-first" }
  );
  const salon = getFragmentData(SalonAllFieldFragmentDoc, meData?.me.salon);
  const selectableCancelTypes =
    salon?.selectableCancelTypesWithoutUnspecifiedOnes ?? [];
  const defaultCouponMasterId =
    getFragmentData(CouponMasterNameFragmentDoc, salon?.defaultCouponMaster)
      ?.id ?? undefined;
  const [couponMasterId, setCouponMasterId] = useState<string | undefined>(
    defaultCouponMasterId
  );
  useEffect(() => {
    // 読み込み完了したら更新する
    if (defaultCouponMasterId) {
      setCouponMasterId(defaultCouponMasterId);
    }
  }, [defaultCouponMasterId]);

  const { data: couponMastersData } = useQuery<
    GetCouponMastersQuery,
    GetCouponMastersQueryVariables
  >(GetCouponMastersDocument, {
    variables: { param: { isActive: true } },
    fetchPolicy: "no-cache",
  });
  const couponMasters = getFragmentData(
    CouponMasterForListFragmentDoc,
    couponMastersData?.couponMasters
  );

  const [createCancel] = useMutation<
    CreateCancelMutation,
    CreateCancelMutationVariables
  >(CreateCancelDocument);

  const onSubmit = async (data: CancelForm) => {
    if (!invoicePrice || invoicePrice <= 50) {
      alert("金額が小さいため、請求できません。予約金額を確認してください。");
      return;
    }
    if (window.confirm("キャンセル情報を作成しますか？")) {
      createCancel({
        variables: {
          input: {
            cancelType,
            paymentStatus,
            phoneNumber: data.phoneNumber,
            name: data.name,
            originPrice: data.originPrice,
            reservationDate: visitDate.toISOString().slice(0, 10),
            staffName: null,
            visitCounts: data.visitCounts ?? 0,
            couponMasterId: couponMasterId ?? null,
          },
        },
      }).then(() => {
        if (paymentStatus === "CLAIMED") {
          // TODO: 本当はこの時、請求済みタブを開いた状態でホームに行くと良い
          alert("キャンセルを請求済として作成しました");
        } else {
          alert("キャンセルを未請求として作成しました");
        }
        navigate("/");
      });
    }
  };

  return (
    <div className={styles.new_cancel}>
      <div className={styles.title}>
        <h1>キャンセル情報作成</h1>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <SimpleInputLabel title="キャンセル種別" optional={false} bold />
        <select
          className={styles.cancel_type_select}
          value={cancelType}
          // @ts-ignore
          onChange={(e) => setCancelType(e.target.value)}
        >
          {selectableCancelTypes.map((cancelType) => (
            <option value={cancelType}>
              {convertCancelTypeToString(cancelType)}
            </option>
          ))}
        </select>

        <SimpleInputLabel title="請求" optional={false} bold />
        <select
          className={styles.claim_select}
          style={{ color: paymentStatus === "CLAIMED" ? "#ff8f4f" : "" }}
          value={paymentStatus}
          // 微妙...
          // @ts-ignore
          onChange={(e) => setPaymentStatus(e.target.value)}
        >
          <option className={styles.claim_option} value="CLAIMED">
            請求する
          </option>
          <option value="NOT_CLAIMED">未請求</option>
        </select>

        <SimpleInputLabel title="電話番号" optional={false} bold />
        <input
          type="number"
          className={styles.phone_number_input}
          placeholder="09000000000"
          {...register("phoneNumber")}
        />
        <div className={styles.warning}>{errors.phoneNumber?.message}</div>

        <SimpleInputLabel title="請求先" optional={false} bold />
        <input type="text" placeholder="山田 太郎" {...register("name")} />
        <div className={styles.warning}>{errors.name?.message}</div>

        <SimpleInputLabel title="予約金額" optional={false} bold />
        <input
          type="number"
          className={styles.origin_price_input}
          placeholder="0"
          {...register("originPrice")}
        />
        <div className={styles.warning}>{errors.originPrice?.message}</div>

        <SimpleInputLabel title="請求金額" optional={false} bold />
        <div>{invoicePrice !== undefined ? invoicePrice : " "}</div>

        <SimpleInputLabel title="来店予定日" optional={false} bold />
        <DatePicker
          dateFormat="yyyy/MM/dd"
          locale="ja"
          selected={visitDate}
          onChange={(value) => {
            setVisitDate(value || new Date());
          }}
          onKeyDown={(e) => {
            // keyboard入力を防ぐ
            // ref: https://stackoverflow.com/questions/68527977/react-datepicker-disable-entering-date-manually
            e.preventDefault();
          }}
        />

        <SimpleInputLabel title="来店回数" optional />
        <input type="number" placeholder="0" {...register("visitCounts")} />
        <div className={styles.warning}>{errors.visitCounts?.message}</div>

        {/* なぜかこれあると請求先とかが壊れる */}
        {/* <SimpleInputLabel title="担当スタッフ" optional />
        <input type="text" placeholder="田中 太郎" {...register("name")} /> */}

        <SimpleInputLabel title="特典" optional={false} bold />
        <select
          className={styles.cancel_type_select}
          value={couponMasterId}
          onChange={(e) => {
            const id = e.target.value;
            if (id === "no_select") {
              setCouponMasterId(undefined);
              return;
            }
            setCouponMasterId(e.target.value);
          }}
        >
          {couponMasters?.map((couponMaster) => (
            <option key={couponMaster.id} value={couponMaster.id}>
              {couponMaster.name}
            </option>
          ))}
          <option key="no_select" value="no_select">
            設定しない
          </option>
        </select>

        <button className={`common-button ${styles.create_button}`}>
          作成する
        </button>
      </form>
    </div>
  );
};

export default NewCancelPage;
