import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import * as Popover from "@radix-ui/react-popover";
import styles from "./QuestionPopover.module.scss";
import { Slot } from "@radix-ui/react-slot";

type Props = {
  contentMessage?: string;
};

export default function QuestionPopover({
  contentMessage,
  children,
}: { children?: React.ReactNode } & Props) {
  return (
    <Popover.Root>
      <Popover.Trigger className={styles.trigger}>
        <QuestionMarkCircledIcon className={styles.icon} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className={styles.content}>
          <div>{contentMessage}</div>
          <Slot>{children}</Slot>
          <Popover.Arrow className={styles.arrow} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
