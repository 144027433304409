import {
  resetPassword,
  type ResetPasswordOutput,
  confirmResetPassword,
} from "aws-amplify/auth";
import styles from "./ForgotPasswordPage.module.scss";
import { LabeledForm } from "components/ui/LabeledForm/LabeledForm";
import { useState } from "react";
import { isValidEmail } from "utils/strings/email";
import { getPasswordError } from "domain/login";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordPage = () => {
  // ui
  const [settingEmail, setSettingEmail] = useState(true);

  // email
  const [username, setUsername] = useState("");
  const [emailError, setEmailError] = useState("");

  // password
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [confirmationCode, setConfirmationCode] = useState("");

  const navigate = useNavigate();

  // phase 1
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setUsername(newEmail);
    if (!isValidEmail(newEmail)) {
      setEmailError("正しいメールアドレスを入力してください。");
    } else {
      setEmailError("");
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!isValidEmail(username)) {
      return;
    }

    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output);
      setSettingEmail(false);
    } catch (error) {
      console.log(error);
      alert("エラーが発生しました。しばらく経ってから再度お試しください。");
    }
  };

  // phase 2
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    const error = getPasswordError(newPassword);
    setPasswordError(error);
    setPassword(newPassword);
  };
  const handleResetPasswordNextSteps = (output: ResetPasswordOutput) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case "DONE":
        console.log("Successfully reset password.");
        break;
    }
  };
  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();
    try {
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword: password,
      });
      alert("パスワードを再発行しました。");
      navigate("/");
    } catch (error) {
      console.log(error);
      alert("確認コードが正しくありません。");
    }
  };

  return (
    <div>
      <div className={styles.login_form}>
        <div className={styles.text}>
          <h1>パスワード再発行</h1>

          {settingEmail ? (
            <div>
              <LabeledForm
                labelText="メールアドレスを入力"
                input={{
                  name: "email",
                  type: "text",
                  value: username,
                  onChange: handleEmailChange,
                }}
                errorMessage={emailError}
              />
              <button
                type="submit"
                className="common-button"
                onClick={handleResetPassword}
              >
                確認メールを送信
              </button>
              <a href="/">戻る</a>
            </div>
          ) : (
            <div>
              <LabeledForm
                labelText="確認コード(6桁)"
                input={{
                  name: "confirmation_code",
                  type: "number",
                  value: confirmationCode,
                  onChange: (e) => setConfirmationCode(e.target.value),
                }}
                errorMessage=""
              />
              <LabeledForm
                labelText="新しいパスワード"
                input={{
                  name: "password",
                  type: showPassword ? "text" : "password",
                  value: password,
                  onChange: handlePasswordChange,
                }}
                errorMessage={passwordError}
              />

              {/* copied from Login.tsx */}
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={showPassword}
                onChange={(e) => setShowPassword(!showPassword)}
              />
              <label className={styles.login_label}>パスワードを表示</label>

              <button
                type="submit"
                className="common-button"
                onClick={handleConfirmResetPassword}
              >
                パスワードを更新
              </button>

              <a href="/forgot_password">戻る</a>
              <p className={styles.note}>
                メールが届かない場合は、メールアドレスを再度ご確認後、再送信してください。
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
