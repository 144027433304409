import React, { useEffect, useState } from "react";
import styles from "./PaymentComplete.module.css";
import { useParams, useNavigate } from "react-router-dom";
import apiService from "components/provider/rest";
function PaymentComplete() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [couponId, setCouponId] = useState<string | undefined>(undefined);
  const [couponIssuedText, setCouponIssuedText] = useState<string | undefined>(
    undefined
  );
  const [checkCouponText, setCheckCouponText] = useState<string | undefined>(
    undefined
  );
  const paymentID = useParams().payment_id;
  useEffect(() => {
    const confirmPayment = async () => {
      await apiService
        .post("/public/payment/confirm", {
          payment_id: paymentID,
        })
        .then((res) => {
          console.log("resj");
          setAmount(res.amount);
          setCouponId(res.coupon_id);
          setCouponIssuedText(res.coupon_issued_text);
          setCheckCouponText(res.check_coupon_text);
          return res;
        })
        .catch((error: Error) => {
          console.log("error", error.message);
          alert("支払いは完了していません。");
          navigate("/payment/" + paymentID);
        });
    };
    confirmPayment();
  }, [paymentID]);
  if (!amount) {
    return <div>loading...</div>;
  }
  return (
    <div className={styles.main_content}>
      <svg
        className={styles.circle}
        width="50"
        height="50"
        viewBox="0 0 100 100"
      >
        <path
          className={styles.outline}
          d="M 50, 90
            a 40,40 0 1,1 0,-80
            a 40,40 0 1,1 0,80"
          fill="none"
          stroke="#FF8F4F"
          stroke-width="2"
        />
        <path
          className={styles.checkmark}
          d="M20,50 L40,70 L80,30"
          fill="none"
          stroke="#FF8F4F"
          stroke-width="6"
        />
      </svg>
      <h2>お支払いが完了しました</h2>
      <p>￥{amount}の決済が正常に行われました</p>
      {couponId && couponIssuedText && checkCouponText && (
        <>
          <h3 className={styles.coupon_text}>{couponIssuedText}</h3>
          <p>使用期限がありますのでご注意ください</p>
          <button
            className={`common-button ${styles.payment_button}`}
            onClick={() => {
              navigate(`/coupon/${couponId}`);
            }}
          >
            {checkCouponText}
          </button>
        </>
      )}
    </div>
  );
}

export default PaymentComplete;
