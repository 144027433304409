import {
  Role,
  SalonUserAllFieldFragment,
  UserPermission,
} from "gql/__generated__/graphql";

export const tableHeaders = [
  "メールアドレス",
  "役割",
  "招待日",
  "最終ログイン日",
  "アクション",
];

export const role_text = (role: Role) => {
  switch (role) {
    case "ADMIN":
      return "管理者";
    case "OWNER":
      return "管理者 (オーナー)";
    case "MEMBER":
      return "メンバー";
    default:
      return "";
  }
};

// buttons visibility
export const inviteSalonUserButtonVisible = (
  userPermission?: UserPermission
) => {
  return userPermission?.canInviteUserSalon ?? false;
};
export const editRoleButtonVisible = (
  salonUser: SalonUserAllFieldFragment,
  mySalonUser: SalonUserAllFieldFragment | null,
  userPermission?: UserPermission
) => {
  // 1. can't edit because of my permission
  if (!userPermission?.canUpdateSalonUserRole) {
    return false;
  }
  // 2. can't edit my role
  if (!mySalonUser || salonUser.id === mySalonUser.id) {
    return false;
  }
  // 3. can't edit salon owner
  if (salonUser.role === "OWNER") {
    return false;
  }
  return true;
};
export const switchOwnerButtonVisible = (
  salonUser: SalonUserAllFieldFragment,
  mySalonUser: SalonUserAllFieldFragment | null,
  userPermission?: UserPermission
) => {
  // 1. can't switch because of my permission
  if (!userPermission?.canSwitchSalonOwner) {
    return false;
  }
  // 2. no need to show button if my role is owner
  if (!mySalonUser || salonUser.id === mySalonUser.id) {
    return false;
  }
  // 3. can't provide owner role to a user that hasn't logged in yet
  if (salonUser.lastLoginTime === null) {
    return false;
  }
  return true;
};
export const reinviteButtonVisible = (
  salonUser: SalonUserAllFieldFragment,
  userPermission?: UserPermission
) => {
  return (
    inviteSalonUserButtonVisible(userPermission) &&
    salonUser.lastLoginTime === null // not logged in yet
  );
};
export const deleteButtonVisible = (
  salonUser: SalonUserAllFieldFragment,
  mySalonUser: SalonUserAllFieldFragment | null,
  userPermission?: UserPermission
) => {
  // 1. can't delete because of my permission
  if (!userPermission?.canDeleteSalonUser) {
    return false;
  }
  // 2. can't delete me
  if (!mySalonUser || salonUser.id === mySalonUser.id) {
    return false;
  }
  // 3. can't delete owner user
  if (salonUser.role === "OWNER") {
    return false;
  }
  return true;
};
export const getVisibility = (visible: boolean) => {
  return visible ? "visible" : "hidden";
};
